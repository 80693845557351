/* eslint-disable no-unused-vars */
import DialogBoxUpgradeSubscription from '_components/DialogBoxUpgradeSubscription'
import LoadingOverlay from '_components/LoadingOverlay'
import { updateNewListProduct } from '_redux/modules/product'
import { PACKAGE_SUBSCRIPTION_BASIC } from '_utils/constant'
import React, { Fragment, useEffect, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import FormCreateProduct from './FormCreateProduct'
import ProductItem from './ProductItem'

function ListProduct({ listProductFilter, loadingShop }) {
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const [isOpenModalCreate, setIsOpenModalCreate] = useState(false)
  const [isOpenModalUpgrade, setIsOpenModalUpgrade] = useState(false)
  const toggleModalCreate = () => setIsOpenModalCreate(!isOpenModalCreate)
  const toggleModalUpgrade = () => setIsOpenModalUpgrade(!isOpenModalUpgrade)

  const { currentPackage } = useSelector((state) => state.subscription)

  const onToggle = () => {
    if (
      (currentPackage === null && listProductFilter.length === 5) ||
      (currentPackage === undefined && listProductFilter.length === 5) ||
      (currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC &&
        listProductFilter.length === 5)
    ) {
      toggleModalUpgrade()
    } else {
      toggleModalCreate()
    }
  }

  useEffect(() => {
    if (selectedShop?.products?.length) {
      dispatch(
        updateNewListProduct(selectedShop.products.sort((a, b) => b.createdAt - a.createdAt))
      )
    }
  }, [selectedShop?.products?.length])

  const tableHeader = [
    { label: 'Image' },
    { label: 'Name' },
    { label: 'Price ($)', style: { textAlign: 'right' } },
    // { label: 'Available of stock' },
    { label: 'In Stock', style: { textAlign: 'right', width: '100px' } },
    { label: 'Purchase', style: { textAlign: 'right' } },
    { label: 'Description' },
    { label: 'Active' },
    { label: 'Setting' },
  ]

  const getClassName = (item) => {
    if (item.label === 'Setting' || item.label === 'Image') {
      return 'text-center'
    }

    return null
  }

  return (
    <div className='list-product position-relative border-start border-end border-bottom'>
      <table className='table'>
        <thead className='product-header f-14'>
          <tr>
            {tableHeader.map((item) => (
              <th key={item.label} className={getClassName(item)} style={item?.style}>
                {item.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='product-content f-14'>
          {loadingShop && (
            <tr>
              <td colSpan={tableHeader.length} width={'100%'}>
                <LoadingOverlay style={{ margin: '50px auto' }} />
              </td>
            </tr>
          )}

          {!loadingShop && listProductFilter?.length
            ? listProductFilter
                .filter((product) => !product?.isDelete)
                .map((product, index) => (
                  <Fragment key={`${product.id}-${index}`}>
                    <ProductItem
                      product={product}
                      pIndex={index}
                      maxLengthProduct={listProductFilter.length - 1}
                      colSpan={tableHeader.length}
                    />
                  </Fragment>
                ))
            : null}

          {!loadingShop && !listProductFilter?.length && (
            <tr>
              <td colSpan={tableHeader.length} width={'100%'}>
                <div className='text-center my-5'>
                  <h5 className='text-secondary'>No Product Found</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={tableHeader.length}>
              <button className='wrap text-center bg-white w-100 py-2' onClick={onToggle}>
                + Add
              </button>
            </td>
          </tr>
        </tfoot>
      </table>

      <Modal isOpen={isOpenModalCreate} toggle={toggleModalCreate} className='modal-create-product'>
        <ModalHeader onClick={toggleModalCreate}>
          Create Product
          <IoIosClose size={30} className='float-end text-end ms-auto cursor-pointer' />
        </ModalHeader>
        <ModalBody>
          <FormCreateProduct toggle={toggleModalCreate} />
        </ModalBody>
      </Modal>

      <DialogBoxUpgradeSubscription isOpen={isOpenModalUpgrade} toggle={toggleModalUpgrade} />
    </div>
  )
}

export default ListProduct
