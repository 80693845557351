import DialogBoxUpgradeSubscription from '_components/DialogBoxUpgradeSubscription'
import {
  copyProduct,
  deleteProduct,
  selectAndToggleProductLazadaModal,
  setOpenSubItem,
} from '_redux/modules/product'
import { LAZADA_FEATURE_PAYMENT_STATUS_APPROVED, PACKAGE_SUBSCRIPTION_BASIC } from '_utils/constant'
import { scrollToTop } from '_utils/function'
import { generateIdWithLength } from '_utils/functions/generator'
import React, { useState } from 'react'
import { AiOutlineSetting } from 'react-icons/ai'
import { FaShareSquare, FaTrashAlt } from 'react-icons/fa'
import { MdContentCopy } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'

function ProductSetting({ product, toggleModal, append, subItems, toggleOpenModalShare }) {
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { addToast } = useToasts()
  const dispatch = useDispatch()

  const { listInvoiceLazada } = useSelector((state) => state.lazada)
  const { currentPackage } = useSelector((state) => state.subscription)

  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct, listProductLazada } = useSelector((state) => state.product)

  const shopApproved =
    listInvoiceLazada.find((item) => item.shopId === selectedShop?.id)?.status ===
    LAZADA_FEATURE_PAYMENT_STATUS_APPROVED

  const toggleModalDialog = () => setIsOpenModal(!isOpenModal)

  const onDelete = (pId, pIndex) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#15cdca',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await dispatch(deleteProduct(selectedShop, pId, pIndex))
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        } catch (error) {
          Swal.fire('Error!', error.message, 'error')
        }
      }
    })
  }

  const onAddSubItem = () => {
    if (subItems.length >= 2) {
      addToast(`Maximum number of sub-items: ${2}`, {
        appearance: 'error',
        autoDismiss: true,
      })
      return
    }

    try {
      append({
        list: [
          {
            price: 0,
            name: '',
            id: generateIdWithLength(20),
          },
        ],
        required: 1, // 1: required, 0: optional
        type: '',
        id: generateIdWithLength(20),
      })
      dispatch(setOpenSubItem(true, product.id, product.index))
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }
  const onCopyProduct = async (_product) => {
    const pid = _product.id
    const pIndex = _product.index

    const conditions = [
      currentPackage === null && listProduct.length === 5,
      currentPackage === undefined && listProduct.length === 5,
      currentPackage?.subscriptionPackageType === PACKAGE_SUBSCRIPTION_BASIC &&
        listProduct.length === 5,
    ]

    try {
      if (conditions.some((condition) => condition)) {
        toggleModalDialog()
      } else {
        await dispatch(copyProduct(selectedShop, pid, pIndex))
        addToast('Product copied successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      }

      scrollToTop()
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onShareProduct = () => {
    const existsProd = listProductLazada.find((item) => item.id === product.id)

    if (!existsProd) {
      dispatch(selectAndToggleProductLazadaModal(product))
      toggleOpenModalShare()
    } else {
      addToast('Product already in Lazada tab', { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      <div className='d-flex align-items-center justify-content-end gap-2 mt-4'>
        <button className='bg-transparent border-0' onClick={toggleModal}>
          <AiOutlineSetting size={27} className='btn-settings' />
        </button>
        <button className='bg-transparent border-0' onClick={() => onCopyProduct(product)}>
          <MdContentCopy size={27} className={'btn-settings'} />
        </button>
        <button className='bg-transparent border-0' onClick={() => onDelete(product?.id)}>
          <FaTrashAlt size={27} className={'btn-settings'} />
        </button>
        {shopApproved && false && (
          <button className='bg-transparent border-0'>
            <FaShareSquare size={27} className={'btn-settings'} onClick={onShareProduct} />
          </button>
        )}
      </div>
      <p
        className='text-end mb-1 cursor-pointer'
        onClick={onAddSubItem}
        style={{ width: 'fit-content', marginLeft: 'auto', marginTop: '10px' }}
      >
        <small className='d-block btn-settings'>
          <u>Add sub-items</u>
        </small>
      </p>

      <DialogBoxUpgradeSubscription isOpen={isOpenModal} toggle={toggleModalDialog} />
    </>
  )
}

export default ProductSetting
