/* eslint-disable func-names */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import { yupResolver } from '@hookform/resolvers/yup'
import { toggleSubItem, updateProduct } from '_redux/modules/product'
import { getDataShopDetail } from '_utils/localData'
import React, { createContext, useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import * as yup from 'yup'
import ModalSetting from '../ModalSetting'
import ModalShare from '../ModalShare'
import ProductDescription from './Description'
import ProductImage from './Image'
import ProductInventory from './Inventory'
import ProductName from './Name'
import ProductPrice from './Price'
import Purchase from './Purchase'
import ProductSetting from './Setting'
import SubItem from './SubItem'

const productSchema = yup.object().shape({
  limitPerOrder: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .required('Limit per order is required')
    .positive('Limit per order must be a number greater than 0'),
  minOrderQty: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .required('Min order quantity is required')
    .positive('Min order quantity must be a number greater than 0')
    .test(
      'lessThanLimitPerOrder',
      'Min order must be less than or equal limit per order',
      function (value) {
        const { limitPerOrder } = this.parent
        return !isNaN(limitPerOrder) && !isNaN(value) && value <= limitPerOrder
      }
    )
    .test(
      'lessThanAvailableStock',
      'Min order must be less than or equal available stock',
      function (value) {
        const { availableOfStock } = this.parent
        return !isNaN(availableOfStock) && !isNaN(value) && value <= availableOfStock
      }
    ),
  price: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .positive('Price must be a positive number')
    .required('Price cannot be empty'),
  availableOfStock: yup
    .number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string') {
        const trimmedValue = originalValue.trim()
        if (trimmedValue === '' || trimmedValue === '-') {
          return undefined
        }
        const numberValue = Number(trimmedValue)
        return isNaN(numberValue) ? undefined : numberValue
      }
      return value
    })
    .positive('Price must be a positive number')
    .required('Price cannot be empty'),
})

export const ProductItemContext = createContext()

function ProductItem({ product, colSpan, pIndex, maxLengthProduct }) {
  const { addToast } = useToasts()
  const shop = getDataShopDetail()
  const dispatch = useDispatch()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const [isOpenModalShare, setIsOpenModalShare] = useState(false)

  const {
    handleSubmit,
    register,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...product,
    },
    resolver: yupResolver(productSchema),
  })

  const { fields, append, insert, remove } = useFieldArray({
    name: 'subItems',
    control,
  })

  const watchSubItems = watch('subItems')
  const controlledSubItems = fields.map((field, index) => ({
    ...field,
    ...watchSubItems[index],
  }))
  const watchProduct = watch()

  useEffect(() => {
    Object.keys(product).forEach((key) => {
      setValue(key, product[key])
    })
  }, [product, setValue])

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal)
  }

  const toggleOpenModalShare = () => setIsOpenModalShare(!isOpenModalShare)

  const handleSave = async (data) => {
    const postData = {
      ...data,
      active: data?.active ? 1 : 0,
      photoUrls: data?.photoUrls,
      minOrderQty: data?.minOrderQty || null,
      limitPerOrder: data.limitPerOrder,
      price: data.price,
      availableOfStock: data.availableOfStock,
      subItems: data.subItems.map((subItem) => {
        const { id, ...restSubItem } = subItem

        const updatedList = restSubItem.list.map((option) => {
          const { id: _id, ...restOption } = option

          return { ...restOption, price: Number(restOption.price) }
        })

        return {
          ...restSubItem,
          list: updatedList,
        }
      }),
    }

    try {
      await dispatch(updateProduct(postData, shop))

      addToast('Updated product successfully', { appearance: 'success', autoDismiss: true })
    } catch (error) {
      addToast(error.message, { appearance: 'error', autoDismiss: true })
    } finally {
      if (isOpenModal) {
        setIsOpenModal(false)
      }
    }
  }

  const shareValues = {
    handleSubmit,
    register,
    isSubmitting,
    product: watchProduct,
    handleSave,
    setValue,
    errors,
  }

  return (
    <ProductItemContext.Provider value={shareValues}>
      <tr {...(product.isCopied ? { className: 'flash' } : {})}>
        <td scope='row' className='col-image' style={{ width: 150 }}>
          <ProductImage product={product} pIndex={pIndex} maxLengthProduct={maxLengthProduct} />
        </td>

        <td className='col-name' style={{ width: 150 }}>
          <ProductName productName={product?.name} watchName={watchProduct?.name} />
        </td>
        <td className='col-price'>
          <ProductPrice productName={product?.name} productPrice={watchProduct?.price} />
        </td>
        <td className='col-inventory'>
          <ProductInventory
            // productInventory={watchProduct?.inventory}
            availableOfStock={watchProduct?.availableOfStock}
            product={product}
          />
        </td>
        <td>
          <Purchase
            purchaseCount={watchProduct?.purchaseCount}
            pid={product?.id}
            {...{ register }}
          />
        </td>
        <td className='col-description' style={{ width: 150 }}>
          <ProductDescription
            productDescription={watchProduct?.description}
            productName={product?.name}
            {...{ register }}
          />
        </td>
        {/* {shop?.lazadaFeature && (
          <td className='col-platforms cursor-pointer text-center'>
            <Platforms product={product} />
          </td>
        )} */}
        <td className='col-active'>
          <label className='switch'>
            <input type='checkbox' defaultChecked={product.active} {...register('active')} />
            <span className='slider round' />
          </label>
        </td>
        <td className='col-setting'>
          <ProductSetting
            toggleModal={toggleModal}
            toggleOpenModalShare={toggleOpenModalShare}
            product={product}
            subItems={controlledSubItems}
            {...{ append }}
          />
        </td>
      </tr>
      <tr className={`p-0 ${product.isCopied ? 'flash' : ''}`}>
        <td colSpan={colSpan} className='border-top-0 pt-0'>
          <div className='d-flex align-items-center w-100'>
            {product?.isOpenSubItems && (
              <button
                className='btn btn-primary rounded-pill shadow-none w-auto text-uppercase text-white ms-auto me-2 mb-4 btn-save'
                onClick={handleSubmit(handleSave)}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </button>
            )}
            <div
              className={`d-flex align-items-end justify-content-center flex-column-reverse ${
                product?.isOpenSubItems ? '' : 'ms-auto'
              }`}
            >
              <small className='mt-2'>
                {product?.isOpenSubItems ? 'Hide sub item' : 'Show sub item'}
              </small>
              <button
                className='btn-toggle-sub-item me-3'
                onClick={() => dispatch(toggleSubItem(product.id, product.index))}
              >
                {product?.isOpenSubItems ? (
                  <MdKeyboardArrowUp size={30} />
                ) : (
                  <MdKeyboardArrowDown size={30} />
                )}
              </button>
            </div>
          </div>
        </td>
      </tr>
      {product?.isOpenSubItems && (
        <tr>
          <td style={{ backgroundColor: '#f8f8f8' }} colSpan={colSpan}>
            {controlledSubItems?.length > 0 && (
              <SubItem
                subItems={controlledSubItems}
                product={product}
                {...{ register, append, insert, remove, getValues, setValue, watch }}
              />
            )}
          </td>
        </tr>
      )}
      <ModalSetting isModal={isOpenModal} toggleModal={toggleModal} product={watchProduct} />

      <ModalShare isOpenModalShare={isOpenModalShare} toggleOpenModalShare={toggleOpenModalShare} />
    </ProductItemContext.Provider>
  )
}

export default ProductItem
