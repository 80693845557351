import { clearShopAndShopDetail, restoreMyShop } from '_redux/modules/shop'
import { getSelectedShopLocalStorage, removeSelectedShopLocalStorage } from '_utils/localData'
import React from 'react'
import { MdSettingsBackupRestore } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'

function ListShopsDeleted() {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { myShopsDeleted } = useSelector((state) => state.shop)
  const selectedShopLocalStorage = getSelectedShopLocalStorage()

  const onRestore = async (shopId) => {
    try {
      await dispatch(restoreMyShop(shopId))

      if (selectedShopLocalStorage?.id) {
        dispatch(clearShopAndShopDetail(null))
        removeSelectedShopLocalStorage()
      }
      addToast('Restored shop successfully', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(e.message || e.msgResp, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      {!myShopsDeleted.length ? (
        <div
          className='text-secondary fw-semibold mt-3 bg-light rounded-3 d-flex align-items-center justify-content-center'
          style={{
            height: 400,
          }}
        >
          No shops to show
        </div>
      ) : (
        <>
          {myShopsDeleted.map((item) => (
            <div
              key={item.id}
              className='bg-white p-3 rounded shadow-sm mt-3 d-flex align-items-center'
            >
              <span className='fw-bold f-18'>{item.shopName}</span>
              <button
                className='btn bg-transparent text-secondary btn-action-shop w-auto ms-auto rounded shadow-none'
                onClick={() => onRestore(item.id)}
              >
                <MdSettingsBackupRestore size={20} />
              </button>
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default ListShopsDeleted
