import axiosClient from './axiosClient'

const deliveryApi = {
  getListDeliveryOptions: (shopId) => {
    const url = `/list-delivery-option?shopId=${shopId}`
    return axiosClient.get(url)
  },
  getListShopDeliveryByHost: (postData) => {
    const url = '/list-shop-delivery-by-host'
    return axiosClient.get(url, {
      params: {
        start: postData.startDate,
        end: postData.endDate,
        shopId: postData.shopId,
      },
    })
  },
  getListShopPickupByHost: (postData) => {
    const url = '/list-shop-pickup-by-host'
    return axiosClient.get(url, {
      params: {
        start: postData.startDate,
        end: postData.endDate,
        shopId: postData.shopId,
      },
    })
  },
  updateDeliveryStatus: (ref, data) => {
    const url = '/update-shop-delivery-status'
    return axiosClient.patch(url, { ...data, ref })
  },
  updatePickupStatus: (ref, data) => {
    const url = '/update-shop-pickup-order-status'
    return axiosClient.patch(url, { ...data, ref })
  },
  getListPickUpOptions: (shopId) => {
    const url = `/list-pick-up-option?shopId=${shopId}`
    return axiosClient.get(url)
  },
  updateDeliveryForOrder: (orderRef, data) => {
    const url = `/update-shop-order-fulfillment?orderRef=${orderRef}`
    return axiosClient.patch(url, data)
  },
}

export default deliveryApi
