import { CheckoutContext } from '_contexts/CheckoutContext'
import { DELIVERY_OPTION, SELF_PICK_UP, TURN_ON } from '_utils/constant'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FeesInfo from './FeesInfo'

function RenderListSingle({ item, isOrderRef }) {
  const { name, price, quantityInput, subItems } = item

  const priceNoSubItems = item?.priceNoSubItems
  const { pathname } = useLocation()
  const orderRefParams = pathname.split('/')[pathname.split('/').length - 1]

  const calculateTotalPrice = () => {
    const basePrice =
      priceNoSubItems && orderRefParams.length >= 18 && orderRefParams.length <= 21
        ? priceNoSubItems
        : price

    const totalSubItemsPrice = subItems.length
      ? subItems.reduce((acc, curr) => acc + curr.price, 0)
      : 0
    const totalPrice = (basePrice + totalSubItemsPrice) * quantityInput
    return totalPrice || 0
  }

  return (
    <>
      {quantityInput > 0 && (
        <div className='row'>
          <div className='col-6 col-md-6 product-name' style={{ overflowWrap: 'anywhere' }}>
            <ol className='toc-list' role='list'>
              <li>
                <span>
                  {name}
                  {isOrderRef ? (
                    <>
                      {item?.priceNoSubItems === 0 ? (
                        ': (Free)'
                      ) : (
                        <>
                          :{' '}
                          <CurrencyFormat
                            className='text-secondary fw-bold'
                            value={item?.priceNoSubItems || 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {item?.price === 0 ? (
                        ': (Free)'
                      ) : (
                        <>
                          :{' '}
                          <CurrencyFormat
                            className='text-secondary fw-bold'
                            value={item?.price || 0}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </>
                      )}
                    </>
                  )}
                </span>
                {subItems.length ? (
                  <ol>
                    {subItems.map((subItem, index) => (
                      <li key={index}>
                        <span className='product-item'>
                          {subItem.name}{' '}
                          {subItem.price === 0 ? (
                            ': (Free)'
                          ) : (
                            <>
                              :{' '}
                              <CurrencyFormat
                                className='text-secondary fw-bold'
                                value={subItem.price || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                            </>
                          )}
                        </span>
                      </li>
                    ))}
                  </ol>
                ) : null}
              </li>
            </ol>
          </div>
          <div className='col-3 col-md-3 product-quantity'>QTY: {quantityInput}</div>
          <div className='col-3 col-md-3 product-total-price text-end text-break'>
            <CurrencyFormat
              value={calculateTotalPrice()}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />{' '}
            SGD
          </div>
        </div>
      )}
    </>
  )
}

function Bill({ cart }) {
  const [subTotal, setSubTotal] = useState(0)
  const { pathname } = useLocation()
  const orderRefParams = pathname.split('/')[pathname.split('/').length - 1]
  const { shop } = useContext(CheckoutContext)
  const isOrderRef = orderRefParams.length >= 18 && orderRefParams.length <= 21

  const { orderDetailsByRef } = useSelector((state) => state.order)
  const { selectedDelivery, selectedPickUp, fulfillmentType } = useSelector(
    (state) => state.fulfillment
  )

  const deliveryFee = fulfillmentType === DELIVERY_OPTION ? selectedDelivery?.deliveryFee || 0 : 0
  const pickupFee = fulfillmentType === SELF_PICK_UP ? selectedPickUp?.pickupFee || 0 : 0

  const totalSubItemsPrice = (arr) => {
    if (arr.length === 0) {
      return 0
    }

    if (arr.length === 1) {
      return arr[0].price
    }

    return arr.reduce((acc, curr) => acc + curr.price, 0)
  }

  useEffect(() => {
    if (cart?.orderList?.length) {
      const calcSubtotal = cart?.orderList?.reduce((acc, curr) => {
        const subItemsPrice = totalSubItemsPrice(curr.subItems)
        let basePrice = 0
        if (curr?.priceNoSubItems && isOrderRef) {
          basePrice = curr?.priceNoSubItems + subItemsPrice
        } else {
          basePrice = curr.price + subItemsPrice
        }

        const productTotalPrice = basePrice * curr.quantityInput
        return acc + productTotalPrice
      }, 0)

      setSubTotal(calcSubtotal)
    }
  }, [cart?.orderList?.length])

  const renderTransactionFees = () => {
    if (shop?.transactionFeesStatus === TURN_ON && isEmpty(orderDetailsByRef)) {
      return parseFloat(shop?.transactionFees || 0)
    }

    if (orderDetailsByRef?.transactionFees) {
      return parseFloat(orderDetailsByRef?.transactionFees)
    }

    return 0
  }

  return (
    <>
      <p className='my-3 text-uppercase title'>Order Summary</p>
      <div className='bg-white p-3 rounded bill'>
        {cart?.orderList?.length > 0
          ? cart?.orderList?.map((item, i) => (
              <RenderListSingle key={i} item={item} isOrderRef={isOrderRef} />
            ))
          : null}

        <hr className='bg-black' />
        <div className='total-price text-black'>
          <div className='row'>
            <div className='col-12'>
              <FeesInfo
                subTotal={subTotal}
                deliveryFee={deliveryFee}
                pickupFee={pickupFee}
                transactionFees={renderTransactionFees()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bill
