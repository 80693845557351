import LoadingOverlay from '_components/LoadingOverlay'
import Pagination from '_components/Pagination'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { DeliveryContext } from '_contexts/DeliveryContext'
import useQuery from '_hooks/useQuery'
import NotFoundData from '_images/not-found-data.jpg'
import { getListOrderFulfillment, setListDateIsPicked } from '_redux/modules/order'
import { getListCombinedPayment } from '_redux/modules/orderEvent'
import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import DeliveryItem from './DeliveryItem'
import Map from './Map'
import ModalPaymentScreen from './ModalPaymentScreen'

const DeliveryList = () => {
  const {
    filteredOrdersFulfillment,
    search,
    currentPage,
    setCurrentPage,
    loading,
    listDeliveryAndPickupShop,
    dataModal,
    modalPaymentScreen,
    togglePaymentScreen,
    handleInvalidPaymentDeliveryEvent,
  } = useContext(DeliveryContext)

  const dispatch = useDispatch()
  const query = useQuery()
  const deliveryIdParam = query.get('deliveryId')
  const headerScrollBarRef = useRef(null)
  const bodyScrollBarRef = useRef(null)

  const headers = [
    {
      name: 'Seq',
      field: '',
      className: 'col text-center',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Orders',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: !deliveryIdParam ? 'Orders/Qty' : 'Zone/Price',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Fulfillment',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Fulfill Date',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    deliveryIdParam && {
      name: 'Booker status',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
    {
      name: 'Status',
      field: '',
      className: 'col',
      type: 'string',
      sortTable: true,
    },
  ]

  const { loadingCurrentDeliveryAndBooking, currentDeliveryAndDeliveryBooking } = useSelector(
    (state) => state.deliveryEvent
  )
  const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

  const { listDateIsPicked } = useSelector((state) => state.order)
  const [lstAddress, setLstAddress] = useState([])
  const [orderFulfillment, setOrderFulfillment] = useState(null)
  const [isTableHeaderScrollBar, setIsTableHeaderScrollBar] = useState(false)

  const originalListFulfillment =
    deliveryIdParam && shopEventDeliveryBookings.length
      ? shopEventDeliveryBookings
      : listDeliveryAndPickupShop

  useEffect(() => {
    const headerElement = headerScrollBarRef.current
    const bodyElement = bodyScrollBarRef.current

    if (headerElement && bodyElement) {
      const handleHeaderScroll = () => {
        bodyElement.scrollLeft = headerElement.scrollLeft
      }

      const handleBodyScroll = () => {
        headerElement.scrollLeft = bodyElement.scrollLeft
      }

      headerElement.addEventListener('scroll', handleHeaderScroll)
      bodyElement.addEventListener('scroll', handleBodyScroll)

      return () => {
        headerElement.removeEventListener('scroll', handleHeaderScroll)
        bodyElement.removeEventListener('scroll', handleBodyScroll)
      }
    }
  })

  const initListDeliveryEventCombinedPaymentRef = async (_data) => {
    const listCombinedPaymentRef = [
      ...new Set(_data.map((element) => element.combinedPaymentRef).filter(Boolean)),
    ]

    if (listCombinedPaymentRef.length) {
      await dispatch(getListCombinedPayment(listCombinedPaymentRef))
    }
  }

  useEffect(() => {
    if (deliveryIdParam && filteredOrdersFulfillment.length) {
      initListDeliveryEventCombinedPaymentRef(filteredOrdersFulfillment)
    }
  }, [deliveryIdParam, JSON.stringify(filteredOrdersFulfillment)])

  useEffect(() => {
    const address = []

    filteredOrdersFulfillment?.forEach((d, index) => {
      if (d.status === 1 || d.status === 0) {
        if (d?.giftRecipientInfo) {
          address.push({ index, value: d.giftRecipientInfo?.address })
        } else {
          address.push({ index, value: d?.pickupAddress || d?.uAddress || d?.address })
        }
      }
    })

    setLstAddress(address)
    dispatch(setListDateIsPicked([]))
    dispatch(getListOrderFulfillment(filteredOrdersFulfillment))
  }, [JSON.stringify(filteredOrdersFulfillment)])

  useEffect(() => {
    if (orderFulfillment?.length > 0) {
      const newOrderFulfillment = []
      for (let i = 0; i < orderFulfillment?.length; i++) {
        const index = lstAddress.findIndex(
          (item) => item.value === (orderFulfillment[i]?.uAddress || orderFulfillment[i]?.address)
        )
        newOrderFulfillment.push({ ...orderFulfillment[i], index: index + 1 })
      }
      setOrderFulfillment(newOrderFulfillment)
    }
  }, [orderFulfillment?.length, lstAddress?.length])

  useEffect(() => {
    if (listDateIsPicked?.length > 0) {
      const newDeliveryOrderList = []
      for (let k = 0; k < listDateIsPicked?.length; k++) {
        const orderByListDateIsPicked = filteredOrdersFulfillment?.filter(
          (item) =>
            moment.unix(item?.deliveryTimestamp).format('DD-MM-YYYY') ===
            moment.unix(listDateIsPicked[k]).format('DD-MM-YYYY')
        )
        newDeliveryOrderList?.push(orderByListDateIsPicked)
      }
      return newDeliveryOrderList?.flat()
    }
    return null
  }, [listDateIsPicked?.length, filteredOrdersFulfillment?.length])

  const getIsTableHeaderScrollBar = (value) => setIsTableHeaderScrollBar(value)

  console.log({ loading, loadingCurrentDeliveryAndBooking })

  return (
    <>
      <div className='row pb-5'>
        <div className='col-3'>
          {lstAddress?.length > 0 ? (
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '600px', width: '100%' }} />}
              mapElement={<div style={{ height: '600px' }} />}
              lstAddress={lstAddress}
              listDateIsPicked={listDateIsPicked}
            />
          ) : (
            <div className='w-100 h-100 bg-light d-flex flex-column align-items-center justify-content-center'>
              <span className='fw-semibold'>
                Google Map.
                <FaLocationDot
                  className='text-danger f-20'
                  style={{ transform: 'translateY(-5px)' }}
                />
              </span>
              <small className='text-info px-3 text-center'>
                No orders are currently pending to be displayed on the map.
              </small>
            </div>
          )}
        </div>
        <div className='col-9'>
          {loading || loadingCurrentDeliveryAndBooking ? <LoadingOverlay /> : null}

          {!loading && !loadingCurrentDeliveryAndBooking ? (
            <div className='list-delivery d-flex h-100 flex-column'>
              <div className='table-header'>
                <TableHeaderWithSort
                  headers={headers}
                  className='row p-3 bg-light table-header f-14'
                  scrollBar={!!filteredOrdersFulfillment?.length}
                  getIsTableHeaderScrollBar={getIsTableHeaderScrollBar}
                  headerScrollBarRef={headerScrollBarRef}
                />
              </div>
              <div
                className={`table-body flex-grow-1 ${
                  isTableHeaderScrollBar ? 'overflow-x-scroll overflow-y-auto' : ''
                }`}
                ref={bodyScrollBarRef}
              >
                {filteredOrdersFulfillment?.length
                  ? filteredOrdersFulfillment.map((item, index) => (
                      <DeliveryItem
                        key={item.id}
                        fulfillment={item}
                        fulfillmentIndex={index}
                        maxLengthFulfillment={filteredOrdersFulfillment.length - 1}
                        isTableScrollBar
                      />
                    ))
                  : null}
              </div>
              {!loading && !filteredOrdersFulfillment?.length ? (
                <div className='text-center my-5'>
                  <img src={NotFoundData} style={{ width: 200, height: 200 }} alt='' />
                  <h6 className='text-secondary'>Not found data</h6>
                </div>
              ) : null}
            </div>
          ) : null}

          <Pagination
            className='pagination-bar mt-2'
            currentPage={currentPage}
            totalCount={!search ? originalListFulfillment.length : filteredOrdersFulfillment.length}
            pageSize={5}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>

      <ModalPaymentScreen
        data={dataModal}
        modalPaymentScreen={modalPaymentScreen}
        togglePaymentScreen={togglePaymentScreen}
        handleInvalidPayment={handleInvalidPaymentDeliveryEvent}
      />
    </>
  )
}

export default DeliveryList
