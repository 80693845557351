import { removeAccents, removeDuplicateHyphens } from '_utils/function'
import { domainGenerator } from '_utils/functions/generator'
import React, { useState } from 'react'

const ShopName = (props) => {
  const { register, error, value } = props
  const textAfterHandle = value?.toLowerCase().replace(/\s+/g, ' ').trim()
  const [shopName, setShopName] = useState(textAfterHandle?.split(' ').join('-'))

  const handleOnChange = (e) => {
    setShopName(removeDuplicateHyphens(e.target.value))
  }

  return (
    <div className='mb-3'>
      <label htmlFor='shop-name' className='form-label'>
        Shop Name <span style={{ color: 'red' }}>*</span>
      </label>
      <input
        type='text'
        className='form-control'
        id='shop-name'
        value={value}
        {...register('shopName')}
        onChange={(e) => handleOnChange(e)}
        maxLength={64} // Set the maximum character limit
      />
      <small className='form-text f-12 text-orange'>Maximum 64 characters allowed.</small>
      <small className='errorMessage'>{error}</small>
      <div id='shop-domain' className='form-text text-wrap text-break'>
        Domain: {domainGenerator()}
        {!shopName ? 'example' : removeDuplicateHyphens(removeAccents(shopName))}
      </div>
    </div>
  )
}

export default ShopName
