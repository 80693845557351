/* eslint-disable no-confusing-arrow */
/* eslint-disable function-paren-newline */
import FulfillmentCalendar from '_components/FulfillmentCalendar'
import { CollapseContext } from '_contexts/CollapseMenuContext'
import { clearCart, setListDateIsPicked } from '_redux/modules/order'
import { selectShopAndSelectShopDetail } from '_redux/modules/shop'
import {
  ORDERS_PAGE,
  ORDER_DELIVERY_PAGE,
  PACKAGE_BASIC,
  SUBSCRIPTION_STATUS_UNPAID,
} from '_utils/constant'
import { getUserInfo, removeCreateEventData } from '_utils/localData'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { AiFillCalendar, AiFillShop } from 'react-icons/ai'
import { BsFillCaretLeftFill, BsFillCaretRightFill } from 'react-icons/bs'
import { FaFileInvoiceDollar, FaTruckMoving } from 'react-icons/fa'
import { IoIosNotifications, IoMdSettings } from 'react-icons/io'
import { MdAttachMoney } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import './style.scss'

function RenderFulfillmentCalendar({ isCollapse, typePage, isShowFulfillmentTag }) {
  if (!isCollapse) {
    if (isShowFulfillmentTag) return <FulfillmentCalendar typePage={typePage} />
  }

  return null
}

function Sidebar({ loading }) {
  const dispatch = useDispatch()
  const user = getUserInfo()
  const history = useHistory()
  const { pathname } = useLocation()

  const { listInvoiceSubscription } = useSelector((state) => state.subscription)
  const { cart } = useSelector((state) => state.order)
  const { isCollapse, toggleCollapse } = useContext(CollapseContext)

  const [typePage, setTypePage] = useState(null)
  const [isShowFulfillmentTag, setIsShowFulfillmentTag] = useState(false)
  const [listMenuItem, setListMenuItem] = useState([])

  const { selectedShop, myShops } = useSelector((state) => state.shop)
  const customsColorTheme = selectedShop?.shopColorTheme?.customsColorTheme

  useEffect(() => {
    if (selectedShop?.shopSlug) {
      const shouldDisplayEvents = () => {
        if (listInvoiceSubscription.length === 1) {
          return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
        }
        if (listInvoiceSubscription.length > 1) {
          return listInvoiceSubscription.some(
            (subscription) =>
              subscription.subscriptionPackageType !== PACKAGE_BASIC &&
              subscription.status !== SUBSCRIPTION_STATUS_UNPAID
          )
        }
        return false
      }

      const MENU_ITEMS = [
        {
          title: 'Shop Front',
          icon: <AiFillShop />,
          path: `/admin-console/${selectedShop?.shopSlug}/shop-front`,
          display: true,
        },
        {
          title: 'Dashboard',
          icon: <IoIosNotifications />,
          path: `/admin-console/${selectedShop?.shopSlug}/dashboard`,
          display: true,
        },
        {
          title: 'Products',
          icon: <AiFillCalendar />,
          path: `/admin-console/${selectedShop?.shopSlug}/products`,
          display: true,
        },
        {
          title: 'orders',
          icon: <MdAttachMoney />,
          path: `/admin-console/${selectedShop?.shopSlug}/order-records`,
          display: true,
        },
        {
          title: 'Delivery & Fulfillment',
          icon: <FaTruckMoving />,
          path: `/admin-console/${selectedShop?.shopSlug}/delivery`,
          display: true,
        },
        shouldDisplayEvents() && {
          title: 'Events',
          icon: <IoMdSettings />,
          path: `/admin-console/${selectedShop?.shopSlug}/event`,
          display: true,
        },
        shouldDisplayEvents() && {
          title: 'Manage Delivery Zones',
          icon: <FaTruckMoving />,
          path: `/admin-console/${selectedShop?.shopSlug}/manage-delivery-zones`,
          display: true,
        },
        {
          title: 'Setting',
          icon: <IoMdSettings />,
          path: `/admin-console/${selectedShop?.shopSlug}/setting/general`,
          display: true,
        },
        {
          title: 'Subscription',
          icon: <FaFileInvoiceDollar />,
          path: `/admin-console/${selectedShop?.shopSlug}/subscription`,
          display: true,
        },
        {
          title: 'Account',
          path: '/admin-console/setting/account',
          display: false,
        },
      ].filter(Boolean)

      setListMenuItem(MENU_ITEMS)
    }
  }, [selectedShop?.shopSlug, JSON.stringify(listInvoiceSubscription)])

  const MenuItem = ({ item, index }) => {
    const location = useLocation()

    const isActive = () => {
      const currentPath = location.pathname

      if (item.path.includes('/setting')) {
        return currentPath.includes('/setting')
      }

      return currentPath === item.path || currentPath.startsWith(item.path)
    }

    const handleNavigation = (e, itemPath) => {
      const urlParams = new URLSearchParams(location.search)
      if (urlParams.has('id')) {
        e.preventDefault()
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are editing an event, do you want to discard the data?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, leave',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            removeCreateEventData()
            urlParams.delete('id')
            history.push(itemPath)
          }
        })
      }
    }

    const active = isActive()

    return (
      <li className='d-flex fw-bold align-items-end mb-2 menu-item' key={index}>
        <NavLink
          to={item.path}
          className={`text-uppercase f-9 ${active ? 'text-orange' : ''}`}
          style={{
            color: customsColorTheme?.titleItemColor,
            display: item.display ? 'block' : 'none',
          }}
          exact
          onClick={(e) => handleNavigation(e, item.path)}
        >
          <span className='me-2 icons f-16'>{item.icon}</span>
          {!isCollapse && <span>{item.title}</span>}
          {isCollapse && <span className='tooltip'>{item.title}</span>}
        </NavLink>
      </li>
    )
  }

  const getPathManageOrder = () => {
    if (pathname.includes('order-records')) {
      return `/admin-console/${selectedShop?.shopSlug}/order-records`
    }

    if (pathname.includes('delivery')) {
      return `/admin-console/${selectedShop?.shopSlug}/delivery`
    }
  }

  const handlePathChange = (_pathname) => {
    if (_pathname === getPathManageOrder()) {
      setIsShowFulfillmentTag(true)
      setTypePage(_pathname.includes('order-records') ? ORDERS_PAGE : ORDER_DELIVERY_PAGE)
      dispatch(setListDateIsPicked([]))
    } else {
      setIsShowFulfillmentTag(false)
    }
  }

  useEffect(() => {
    if (selectedShop?.id) {
      handlePathChange(pathname)
    }
  }, [pathname, selectedShop?.id])

  const handleShopChange = (e) => {
    if (!e.target.value) return

    const newSelectedShop = JSON.parse(e.target.value)
    const userShopSlugs = user?.shopLinks?.length
      ? user.shopLinks.map((item) => {
          const arr = item.split('/')
          return arr[arr.length - 1]
        })
      : []

    const userMatchShop = userShopSlugs?.find((item) => item === newSelectedShop?.shopSlug)

    if (userMatchShop) {
      const newPathname = pathname.replace(selectedShop?.shopSlug, newSelectedShop?.shopSlug)
      history.push(newPathname)

      if (cart?.orderList?.length > 0) {
        dispatch(clearCart())
      }
      dispatch(selectShopAndSelectShopDetail(newSelectedShop))
    }
  }

  const selectedShopFromAllShops = useMemo(
    () => myShops.find((shop) => shop.shopSlug === selectedShop?.shopSlug),
    [selectedShop?.shopSlug, JSON.stringify(myShops)]
  )

  return (
    <div className='sidebar pe-0'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex flex-column w-100'>
          {myShops.length > 0 ? (
            <div className={`pe-3 ps-2 ${isCollapse ? 'd-none' : ''}`}>
              <select
                className='custom-form-select mt-1 rounded'
                disabled={loading || pathname.includes('update-confirm')}
                value={JSON.stringify(selectedShopFromAllShops)}
                onChange={handleShopChange}
              >
                {myShops.map((shop, index) => (
                  <option
                    key={index}
                    value={JSON.stringify(shop)}
                    className={shop.active ? 'text-black' : 'text-secondary'}
                  >
                    {shop?.shopName}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          <ul className={`${!isCollapse ? 'pe-3 ps-2' : 'ps-4'} list-menu pt-2`}>
            {listMenuItem.length
              ? listMenuItem.map((item, index) => (
                  <MenuItem key={index} item={item} index={index} />
                ))
              : null}
          </ul>
        </div>
        <div className='style-menu' onClick={toggleCollapse}>
          <div className='group'>
            <span className='text-uppercase'>menu</span>
            <span className='d-inline-block'>
              {isCollapse ? <BsFillCaretRightFill /> : <BsFillCaretLeftFill />}
            </span>
          </div>
        </div>
      </div>
      <div className='w-100 d-flex'>
        <div className='prev-style-menu'>
          <span className='text-uppercase'>
            {pathname.includes('order-records') ? 'Order Dates' : 'Fulfillment'}
          </span>
        </div>
        <div className='calendar w-100'>
          <RenderFulfillmentCalendar
            isCollapse={isCollapse}
            typePage={typePage}
            isShowFulfillmentTag={isShowFulfillmentTag}
          />
        </div>
      </div>
    </div>
  )
}

export default Sidebar
