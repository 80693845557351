import { yupResolver } from '@hookform/resolvers/yup'
import virtualShopApi from '_api/virtualShop'
import { DATES_OF_WEEK, LIST_TIME_2 } from '_utils/constant'
import { getDatesOfCurrentWeek } from '_utils/function'
import { calculateDuration } from '_utils/functions/calculator'
import { convertToGMT0 } from '_utils/functions/converter'
import { getDataShopDetail } from '_utils/localData'
import Holidays from 'date-holidays'
import moment from 'moment'
import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'
import * as Yup from 'yup'
import OpeningDays from './OpeningDays'
import OpeningHours from './OpeningHours'

const validationSchema = Yup.object().shape({
  shopAddress: Yup.string('Invalid shop address')
    .required('Shop address is required')
    .min(10, 'Shop address must be at least 10 characters'),
})

const listDatesOfCurrentWeek = getDatesOfCurrentWeek()
const formatDatesOfWeek = DATES_OF_WEEK.map((item, i) => ({
  ...item,
  date: listDatesOfCurrentWeek[i].date,
}))

function ModalCreatePickup({ modal, toggle }) {
  const { addToast } = useToasts()
  const [isNoPickup, setIsNoPickup] = useState(false)
  const shop = getDataShopDetail()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      datesOfWeek: formatDatesOfWeek,
    },
  })

  const { datesOfWeek } = getValues()

  const [checkedPublicHoliday, setCheckedPublicHoliday] = useState(false)
  const [hours, setHours] = useState(
    calculateDuration(formatDatesOfWeek[0].date, formatDatesOfWeek[6].date, '2:00', '11:00') || {
      hourStart: '9AM',
      hourEnd: '6PM',
    }
  )

  const holidays = new Holidays('SG')
  const currentYear = new Date().getFullYear()

  const showPublicHoliday = () => {
    const currentHolidayYear = holidays.getHolidays(currentYear)
    const publicHolidays = currentHolidayYear.filter((date) => {
      const dateOfHoliday = moment(date.date).format('YYYY-MM-DD')
      return listDatesOfCurrentWeek.some(
        (dateCurrentWeek) => dateCurrentWeek.date === dateOfHoliday
      )
    })

    const newDatesOfWeek = datesOfWeek.map((date) => {
      const isHoliday = publicHolidays.some(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )
      const nameHoliday = publicHolidays.find(
        (holiday) =>
          moment(holiday.date).format('DD-MM-YYYY') === moment(date.date).format('DD-MM-YYYY')
      )

      return {
        ...date,
        isHoliday,
        name: isHoliday ? nameHoliday : '',
      }
    })

    return newDatesOfWeek
  }

  const handleClickDate = (e, i) => {
    const isCheck = e.target.checked

    const newDatesPublicHoliday = datesOfWeek.map((item, _i) => {
      if (_i === i) {
        return { ...item, isClose: isCheck }
      }
      return { ...item }
    })

    reset({ datesOfWeek: newDatesPublicHoliday })
  }

  const handleCheckPublicHoliday = (e) => {
    const isCheck = e.target.checked
    setCheckedPublicHoliday(isCheck)
    if (isCheck) {
      const newDatesOfWeek = showPublicHoliday()

      reset({ datesOfWeek: newDatesOfWeek })
    }
  }

  const convertTimeToGMT0 = (date, time) => {
    const dateAndTime = date + time
    const timeGMT0 = convertToGMT0(dateAndTime).slice(11)
    return timeGMT0
  }

  const updateShopNoDeliveryAndPickup = async (checked) => {
    setIsNoPickup(checked)
    try {
      await virtualShopApi.updateShopNoPickupAndDelivery(shop?.id, {
        fulfillmentStatus: Number(checked), // 0: no pickup and delivery, 1: pickup and delivery
      })
      toggle()
    } catch (error) {
      addToast(error?.msgResp || error?.message, { appearance: 'error', autoDismiss: true })
    }
  }

  const onSubmit = async (data) => {
    try {
      const pickupStartIndex = LIST_TIME_2.findIndex((item) => item.text === hours.hourStart)
      const pickupEndIndex = LIST_TIME_2.findIndex((item) => item.text === hours.hourEnd)
      const timeStart = LIST_TIME_2[pickupStartIndex].valueString
      const timeEnd = LIST_TIME_2[pickupEndIndex].valueString
      const postData = {
        pickupAddress: data?.shopAddress,
        optionName: 'Pickup 01',
        pickupFee: null,
        instructions: '',
        pickupTime: {
          startTime: convertTimeToGMT0(moment(datesOfWeek[0].date).format('DD-MM-YYYY'), timeStart),
          endTime: convertTimeToGMT0(moment(datesOfWeek[6].date).format('DD-MM-YYYY'), timeEnd),
        },
        datesOfWeek,
        isCheckPublicHoliday: checkedPublicHoliday,
        timeSlot: null,
      }

      if (isNoPickup) {
        await updateShopNoDeliveryAndPickup()
      } else {
        await virtualShopApi.createPickUpOption(shop?.id, postData)
      }

      toggle()
      addToast('Created pickup successfully', { appearance: 'success', autoDismiss: true })
    } catch (e) {
      addToast(`${e.msgResp}`, { appearance: 'error', autoDismiss: true })
    }
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered backdrop='static'>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='container text-center'>
              <div className='d-flex flex-column align-items-start'>
                <input
                  type='text'
                  placeholder='Shop Address'
                  className='rounded-pill border mb-1 form-control-sm w-100 f-14'
                  {...register('shopAddress')}
                />
                {errors.shopAddress && (
                  <small className='text-danger'>{errors.shopAddress.message}</small>
                )}
              </div>
              <OpeningDays
                control={control}
                datesOfWeek={datesOfWeek}
                handleClickDate={handleClickDate}
                checkedPublicHoliday={checkedPublicHoliday}
                handleCheckPublicHoliday={handleCheckPublicHoliday}
              />
              <OpeningHours setHours={setHours} hours={hours} />
              <div className='row mt-3'>
                <div className='col-5 m-auto'>
                  <button className='btn btn--org btn--org02' type='submit'>
                    OK
                  </button>
                </div>
                <div className='col-5 m-auto'>
                  <button
                    type='button'
                    className='btn btn-primary-outline text-uppercase'
                    onClick={() => updateShopNoDeliveryAndPickup(true)}
                  >
                    Skip
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ModalCreatePickup
