import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const styleDisabled = {
  pointerEvents: 'none',
  opacity: 0.5,
}

const DeliveryZoneRow = ({ index, zone, onDelete, register, errors, isDisabled }) => (
  <div className='row mb-2' style={isDisabled ? styleDisabled : {}}>
    <div className='col-7'>
      <div className='d-flex flex-column align-items-start'>
        <div className='d-flex align-items-end w-100'>
          <label className='form-label me-1'>{`${index + 1}.`}</label>
          <input
            type='text'
            className={`form-control ${
              errors?.selectedDeliveryZones?.[index]?.name ? 'is-invalid' : ''
            }`}
            defaultValue={zone.name}
            {...register(`selectedDeliveryZones.${index}.name`)}
          />
        </div>
        {errors?.selectedDeliveryZones?.[index]?.name && (
          <div className='invalid-feedback w-100 d-block ps-3'>
            {errors.selectedDeliveryZones[index].name.message}
          </div>
        )}
      </div>
    </div>
    <div className='col-5'>
      <div className='d-flex flex-column align-items-start'>
        <div className='d-flex align-items-center w-100'>
          <span>$</span>
          <input
            type='text'
            className={`form-control ms-2 ${
              errors?.selectedDeliveryZones?.[index]?.price ? 'is-invalid' : ''
            }`}
            defaultValue={zone.price}
            {...register(`selectedDeliveryZones.${index}.price`)}
          />
          <button
            className='btn btn-link text-danger shadow-none p-0 w-auto ms-3'
            onClick={() => onDelete(zone.id)}
          >
            <FaTrashAlt size={20} />
          </button>
        </div>
        {errors?.selectedDeliveryZones?.[index]?.price && (
          <div className='invalid-feedback w-100 d-block ps-3'>
            {errors.selectedDeliveryZones[index].price.message}
          </div>
        )}
      </div>
    </div>
  </div>
)

const DeliveryFee = ({ name }) => {
  const { loadingGetRememberedDeliveryZones } = useSelector((state) => state.deliveryEvent)
  const { control, reset, register, errors } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'selectedDeliveryZones',
  })

  useEffect(() => {
    if (fields.length === 0) {
      append({ name: '', price: 0 })
    }
  }, [fields, append])

  const onAddZone = () => {
    append({ name: '', price: 0 })
  }

  const onDeleteZone = (id) => {
    if (fields.length === 1) {
      reset({ [name]: [{ name: '', price: 0 }] })
    } else {
      const index = fields.findIndex((zone) => zone.id === id)
      remove(index)
    }
  }

  return (
    <div className='mb-3'>
      <label className='form-label'>Edit delivery:</label>
      <div className='delivery-fee-wrapper p-3 rounded'>
        {fields.length > 0 ? (
          <>
            {fields.map((zone, index) => (
              <DeliveryZoneRow
                key={zone.id}
                index={index}
                zone={zone}
                onDelete={onDeleteZone}
                register={register}
                errors={errors}
                isDisabled={loadingGetRememberedDeliveryZones}
              />
            ))}
          </>
        ) : null}
        <button
          className='btn btn-link shadow-none w-auto rounded-0 p-0 text-primary'
          onClick={onAddZone}
        >
          Add more
        </button>
      </div>
    </div>
  )
}

export default DeliveryFee
