/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import noImage from '_images/noimage02.svg'
import { fetchMyShops, setIdShop } from '_redux/modules/shop'
import { fetchListInvoiceSubscription } from '_redux/modules/subscription'
import { NO_DATA_SHOP_DETAIL } from '_utils/constant'
import { removeAccents, removeDuplicateHyphens } from '_utils/function'
import { domainGenerator } from '_utils/functions/generator'
import {
  getCreateShopData,
  getUserToken,
  removeDataShopDetail,
  setCreateShopData,
} from '_utils/localData'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiOutlineInstagram, AiOutlineWhatsApp } from 'react-icons/ai'
import { BsFacebook } from 'react-icons/bs'
import { GiHouse } from 'react-icons/gi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import * as Yup from 'yup'

import ShopName from '../components/ShopName'
import RenderListImage from './components/RenderListImage'
import './style.scss'

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
const phoneRegExp = /^[0-9\- ]{8,14}$/

const shopNameRegex = /^[^\!\@\#\$\%\^\&\*\(\)\=\+\/\*\~\`\|\\\}\]\{\[\"\'\:\;\?\>\<\,]+$/

const validationSchema = Yup.object().shape({
  shopName: Yup.string('Invalid shop name')
    .required('Invalid shop name')
    .min(4, 'Shop name must be at least 4 characters')
    .max(64, 'Shop name must be at most 64 characters')
    .matches(shopNameRegex, 'Shop name must not contain special characters'),
  headline: Yup.string('Invalid headline')
    .nullable()
    .default('')
    .max(1024, 'Headline must be at most 1024 characters'),
  linkInstagram: Yup.string().matches(URL, {
    message: 'invalid link Instagram',
    excludeEmptyString: true,
  }),
  linkFacebook: Yup.string().matches(URL, {
    message: 'invalid link Facebook',
    excludeEmptyString: true,
  }),
  whatsApp: Yup.string()
    .matches(phoneRegExp, 'Invalid whatsApp')
    .nullable()
    .transform((_, val) => (val !== '' ? val : null)),
  shopAddress: Yup.string('Invalid address')
    .min(10, 'Address must be at least 10 characters')
    .max(256, 'Address must be at most 256 characters'),
})

function Create() {
  // const userInfo = getUserInfo()
  const history = useHistory()
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const createShopData = getCreateShopData()
  const [listImageShop, setListImageShop] = useState(
    createShopData?.logo?.map((item) => ({ url: item, file: '' } || [])) || []
  )

  const userToken = getUserToken()

  useEffect(() => {
    if (userToken) {
      dispatch(fetchMyShops())
      removeDataShopDetail()
      dispatch(setIdShop(NO_DATA_SHOP_DETAIL))
      dispatch(fetchListInvoiceSubscription())
    }
  }, [userToken])

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImageShop?.length > 64) {
      addToast('Maximum images for shop is 64!', { appearance: 'error', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 64) {
        listImageShop.push({ url: global.URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImageShop([...listImageShop])
  }

  const handleOnRemoveImg = (index) => {
    listImageShop.splice(index, 1)
    setListImageShop([...listImageShop])
  }

  const onSubmit = async (data) => {
    const textAfterHandle = removeDuplicateHyphens(removeAccents(data.shopName))
    const list = listImageShop

    for (let i = 0; i < list.length; i++) {
      const element = list[i]
      if (element.file) {
        const { msgResp } = await uploadApi.uploadPhoto([element.file])
        element.url = msgResp.url
      }
    }

    if (!createShopData) {
      const postData = {
        // colorScheme,
        shopLink: `${domainGenerator()}${textAfterHandle.split(' ').join('-')}`,
        logo: list.map((item) => item.url),
        shopSlug: removeDuplicateHyphens(data.shopName),
        shopFrontType: 1,
        isShopCompleted: false,
        isShowGuide: true,
        ...data,
      }
      setCreateShopData(postData)
      history.push('/virtual-shop/add-items')
    } else {
      const postData = {
        // colorScheme,
        shopLink: `${domainGenerator()}${textAfterHandle.split(' ').join('-')}`,
        logo: list.map((item) => item.url),
        products: createShopData?.products,
        shopSlug: removeDuplicateHyphens(data.shopName),
        shopFrontType: 1,
        isShopCompleted: false,
        isShowGuide: true,
        ...data,
      }
      setCreateShopData(postData)
      history.push('/virtual-shop/add-items')
    }
  }

  return (
    <div className='container py-3'>
      <div className='virtual-shop create-items-page' style={{ marginTop: 0 }}>
        <p className='text-center title'>Setting Up Your Online Shop</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ShopName
            error={errors?.shopName?.message}
            register={register}
            value={createShopData?.shopName}
          />

          <div className='row mb-3'>
            <p className='add-more-image text-end mb-2 col-12'>
              Remain: ({64 - listImageShop.length} photos left)
            </p>
            <div className='col-12'>
              <RenderListImage
                listImageShop={listImageShop}
                handleOnRemoveImg={handleOnRemoveImg}
                onUserImageUploaded={onUserImageUploaded}
                noImage={noImage}
              />
            </div>
          </div>
          <div className='mb-3'>
            <label htmlFor='headline' className='form-label'>
              Headline to attract buyers
            </label>
            <textarea
              className='form-control'
              id='headline'
              rows='3'
              {...register('headline')}
              defaultValue={createShopData?.headline}
            ></textarea>
            <small className='errorMessage'>{errors?.headline?.message}</small>
          </div>
          <div className='mb-3 row phone-contact'>
            <div className='col-2 col-lg-1'>
              <AiOutlineWhatsApp fontSize={30} color='#A6DFDE' />
            </div>
            <div className='col-10 col-lg-11'>
              <input
                type='text'
                className='form-control'
                id='whats-app'
                defaultValue={createShopData?.whatsApp}
                placeholder='Mobile Number (whatsapp/paynow/paylah)'
                {...register('whatsApp')}
              />
              <small className='errorMessage'>{errors?.whatsApp?.message}</small>
            </div>
          </div>
          <div className='mb-3 row instagram-url'>
            <div className='col-2 col-lg-1'>
              <AiOutlineInstagram fontSize={30} color='#A6DFDE' />
            </div>
            <div className='col-10 col-lg-11'>
              <input
                type='text'
                className='form-control'
                id='instagram'
                {...register('linkInstagram')}
                placeholder='Instagram URL'
                defaultValue={createShopData?.linkInstagram}
              />
              <small className='errorMessage'>{errors?.linkInstagram?.message}</small>
            </div>
          </div>
          <div className='mb-3 row facebook-url'>
            <div className='col-2 col-lg-1'>
              <BsFacebook fontSize={30} color='#A6DFDE' />
            </div>
            <div className='col-10 col-lg-11'>
              <input
                type='text'
                className='form-control'
                id='facebook'
                {...register('linkFacebook')}
                placeholder='Facebook URL'
                defaultValue={createShopData?.linkFacebook}
              />
              <small className='errorMessage'>{errors?.linkFacebook?.message}</small>
            </div>
          </div>
          <div className='mb-3 row address'>
            <div className='col-2 col-lg-1'>
              <GiHouse fontSize={30} color='#A6DFDE' />
            </div>
            <div className='col-10 col-lg-11'>
              <input
                type='text'
                className='form-control'
                id='shopAddress'
                {...register('shopAddress')}
                placeholder='Address'
                defaultValue={createShopData?.shopAddress}
              />
              <small className='errorMessage'>{errors?.shopAddress?.message}</small>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col-6'>
              <button
                type='reset'
                className='btn btn-primary-outline text-uppercase shadow-none'
                style={{ border: '1px solid #0badad' }}
                onClick={() => {
                  localStorage.removeItem('CREATE_SHOP_DATA')
                  history.push('/')
                }}
              >
                Cancel
              </button>
            </div>
            <div className='col-6'>
              <button
                type='submit'
                className='btn btn-primary rounded-pill text-uppercase'
                style={{ border: '1px solid #0badad' }}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner color='light' size='sm' /> : 'Save & Next'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Create
