import { ProductItemLazadaContext } from '_contexts/ProductItemLazadaContext'
import noImage from '_images/noimage.svg'
import { isArray } from 'lodash'
import React, { useContext, useEffect } from 'react'
import CurrencyFormat from 'react-currency-format'
import { IoSync } from 'react-icons/io5'
import { useToasts } from 'react-toast-notifications'

const RenderListVariants = () => {
  const {
    watchListVariationProduct,
    setValue,
    register,
    watchProduct,
    handleUpdateVariationProduct,
    isUpdatingVariationLaz,
    inputFileRefs,
  } = useContext(ProductItemLazadaContext)
  const { addToast } = useToasts()

  const propertyNamesToShow = watchListVariationProduct?.length
    ? Object.keys(watchListVariationProduct[0]?.saleProp)
    : []

  const generateVariationName = (_product, variant) => {
    let productName = _product.name
    const salePropVariant = Object.keys(variant.saleProp)
    const options = []

    salePropVariant.forEach((saleProp) => {
      if (variant.saleProp[saleProp] !== undefined) {
        options.push(variant.saleProp[saleProp])
      }
    })

    const optionsString = options.join(', ')
    if (optionsString) {
      productName += ` ${optionsString}`
    }

    return productName
  }

  useEffect(() => {
    if (
      watchListVariationProduct !== undefined &&
      isArray(watchListVariationProduct) &&
      watchListVariationProduct.length
    ) {
      watchListVariationProduct.forEach((variant, index) => {
        setValue(`listVariationProduct.${index}.name`, generateVariationName(watchProduct, variant))

        if (!variant?.quantity) {
          setValue(`listVariationProduct.${index}.quantity`, 0)
        }
      })
    }
  }, [JSON.stringify(watchListVariationProduct), JSON.stringify(propertyNamesToShow)])

  // Thanh Trung - handle upload image list variation product
  const onUserImageUploaded = async (event, index) => {
    const { files } = event.target

    if (files && files[0]) {
      const img = files[0]
      const imgURL = URL.createObjectURL(img)
      const tempImg = new Image()

      tempImg.onload = () => {
        const { width, height } = tempImg

        if (width >= 330 && height >= 330) {
          setValue(`listVariationProduct.${index}.photoUrl`, imgURL)
        } else {
          addToast('Photo size must be at least 330x330', {
            appearance: 'error',
            autoDismiss: true,
          })
        }

        // Revoke the object URL to free up resources
        // URL.revokeObjectURL(imgURL)
      }

      tempImg.onerror = () => {
        addToast('Can not upload the image', { appearance: 'error', autoDismiss: true })
      }

      tempImg.src = imgURL
    }
  }

  const tableHeaders = [
    'Variants',
    'Image',
    'Active',
    'Price ($)',
    'Inventory',
    'Weight (kg)',
    'Width (cm)',
    'Height (cm)',
    'Length (cm)',
    'Action',
  ]

  return (
    <>
      <div className='d-flex align-items-center justify-content-between my-3'>
        <span className='text-primary fw-bold f-20'>List Variants</span>
      </div>
      <div className='list-variants custom-scrollbar pb-3 my_fixed_table'>
        <table className='table mb-0 position-relative'>
          <thead className='product-header'>
            <tr>
              {tableHeaders.map((header) => (
                <th key={header} scope='col'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='product-content'>
            {watchListVariationProduct?.length ? (
              watchListVariationProduct.map((item, index) => (
                <tr key={`${item.id}-${index}`}>
                  <td className='col-name'>
                    {propertyNamesToShow.map((propertyName, propIndex) => (
                      <span key={`${propertyName}-${propIndex}`}>
                        {propIndex > 0 && ', '}
                        <strong>{item.saleProp[propertyName]}</strong>
                      </span>
                    ))}
                  </td>
                  <td className='col-image'>
                    <div
                      className='c-form upload-image-square position-relative bg-white'
                      style={{
                        height: 75,
                        width: 75,
                        borderRadius: 0,
                        display: 'flex',
                        overflow: 'hidden',
                      }}
                    >
                      <div className='upload-image text-center shadow-none h-100 w-100 rounded-0'>
                        <img
                          src={item.photoUrl || noImage}
                          alt='img'
                          className={'img-fluid object-fit-contain rounded-0'}
                          style={{ maxHeight: '100%', maxWidth: '100%' }}
                        />
                        <div
                          className={`u-file-upload__item position-absolute top-0 bottom-0 start-0 end-0 z-2 opacity-0 ${
                            item?.sku_id ? 'cursor-revert' : 'cursor-pointer'
                          }`}
                        >
                          <div className='u-file-upload__wrap h-100'>
                            <input
                              ref={(el) => {
                                inputFileRefs.current[index] = el
                              }}
                              id={`fileUpload-${index}`}
                              defaultValue={''}
                              type='file'
                              accept='image/*'
                              name='fileUpload'
                              className={'inputFile w-100 h-100 p-0 cursor-pointer'}
                              onChange={(event) => onUserImageUploaded(event, index)}
                              // readOnly={item?.sku_id}
                              // disabled={item.sku_id}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='col-active'>
                    <div className='form-check form-switch'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id={item.id}
                        defaultChecked={item.active}
                        {...register(`listVariationProduct.${index}.active`)}
                      />
                    </div>
                  </td>
                  <td className='col-price' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.price || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.price`, Number(value))
                      }}
                    />
                  </td>
                  <td className='col-inventory' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.quantity || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.quantity`, Number(value))
                      }}
                    />
                  </td>
                  <td className='col-weight' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.weight || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.weight`, value)
                      }}
                      {...register(`listVariationProduct.${index}.weight`)}
                    />
                  </td>
                  <td className='col-width' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.width || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.width`, value)
                      }}
                      {...register(`listVariationProduct.${index}.width`)}
                    />
                  </td>
                  <td className='col-height' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.height || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.height`, value)
                      }}
                      {...register(`listVariationProduct.${index}.height`)}
                    />
                  </td>
                  <td className='col-length' style={{ width: 150 }}>
                    <CurrencyFormat
                      id='product-price'
                      value={item.length || 0}
                      displayType={'input'}
                      thousandSeparator={true}
                      className='form-control-sm border px-1 w-100'
                      onValueChange={(values) => {
                        const { value } = values
                        setValue(`listVariationProduct.${index}.length`, value)
                      }}
                      {...register(`listVariationProduct.${index}.length`)}
                    />
                  </td>
                  <td className='col-action'>
                    {!watchProduct?.isPendingToPush ? (
                      <button
                        type={'button'}
                        className={
                          'shadow-none text-primary p-0 border-0 d-flex align-items-center justify-content-center bg-transparent btn-save-variant mx-auto'
                        }
                        onClick={() => handleUpdateVariationProduct(index)}
                        disabled={isUpdatingVariationLaz}
                      >
                        <IoSync size={20} />
                      </button>
                    ) : (
                      <span className='f-12'>No Actions</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableHeaders.length}>
                  <div className='text-center my-5'>
                    <h5 className='text-secondary'>No Variants Found</h5>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {!watchProduct?.isPendingToPush && watchListVariationProduct?.length > 0 ? (
            <tfoot>
              <tr className='border-none'>
                <td
                  colSpan={0}
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: '-18px',
                    borderTopWidth: 0,
                    padding: 0,
                    width: 'fit-content',
                    marginLeft: 'auto',
                  }}
                >
                  <button
                    type={'button'}
                    className={
                      'btn btn-primary text-white shadow-none rounded-pill w-auto float-end btn-save-variant'
                    }
                    onClick={handleUpdateVariationProduct}
                    disabled={isUpdatingVariationLaz}
                  >
                    Sync On List
                  </button>
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
      </div>
    </>
  )
}
export default RenderListVariants
