import uploadApi from '_api/upload'
import { useHover } from '_hooks/useHover'
import noImage from '_images/noimage02.svg'
import { swapDownProduct, swapUpProduct, updateNewListProduct } from '_redux/modules/product'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { ProductItemContext } from '.'

function ProductImage({ product, pIndex, maxLengthProduct }) {
  // For interaction
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { setValue, handleSave } = useContext(ProductItemContext)

  const { listProduct } = useSelector((state) => state.product)

  const [loading, setLoading] = useState(null)
  const [hoverRef, isHovered] = useHover()
  const [hoverRef2, isHovered2] = useHover()

  const getImage = () => {
    if (product?.imgFileUrl) {
      return product?.imgFileUrl
    }

    if (product?.photoUrls?.length > 0) {
      return product.photoUrls
    }

    return noImage
  }

  const onUploadImg = async (e) => {
    try {
      const { files } = e.target
      setLoading(product.id)
      const { msgResp } = await uploadApi.uploadPhoto(files)
      setValue('photoUrls', msgResp.url)
      const findProduct = listProduct.findIndex((item) => item.id === product.id)
      const updatedList = [...listProduct]
      updatedList[findProduct].photoUrls = msgResp.url
      dispatch(updateNewListProduct(updatedList))
      await handleSave(updatedList[findProduct])
    } catch (error) {
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      setLoading(null)
    }
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='wrap-arrow me-2 cursor-pointer'>
        {pIndex !== 0 ? (
          <div className='arrow-up' ref={hoverRef} onClick={() => dispatch(swapUpProduct(product))}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill={isHovered ? '#74a8a7' : '#A6DFDE'}
            >
              <path d='M12 3l12 18h-24z' />
            </svg>
          </div>
        ) : null}

        {pIndex !== maxLengthProduct ? (
          <div
            className='arrow-down'
            ref={hoverRef2}
            onClick={() => dispatch(swapDownProduct(product))}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill={isHovered2 ? '#74a8a7' : '#A6DFDE'}
            >
              <path d='M12 21l-12-18h24z' />
            </svg>
          </div>
        ) : null}
      </div>
      <div
        className='upload-image flex-column cursor-pointer position-relative'
        // style={{ width: 90, height: 80 }}
      >
        {loading === product.id ? (
          <div
            className='loading d-flex align-items-center justify-content-center bg-gray-300 rounded'
            style={{ width: 90, height: 80 }}
          >
            <div className='spinner-border text-primary' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <img
            src={getImage()}
            className={`${
              product?.imgFileUrl?.length > 0 ? 'img-upload' : ''
            } image img-fluid object-fit-contain`}
            alt='img'
          />
        )}
        <div className='u-file-upload__item position-absolute top-0 start-0 bottom-0 end-0'>
          <div className='u-file-upload__wrap h-100'>
            <a className='c-upload preview-images-zone'>
              <input
                type='file'
                accept='image/*'
                id={`file-upload-${pIndex}`}
                className='inputFile form-control w-100 h-100 opacity-0'
                onChange={(e) => onUploadImg(e)}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductImage
