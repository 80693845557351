import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

const EventExternalURL = (props) => {
  const { modal, toggle, value, onChange } = props
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalBody>
        <div className='container p-0'>
          <h5 className='text-center'>Add external URL</h5>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1 f-16'>Field Name:</p>
            <input
              className='form-control f-16'
              value={value?.name}
              onChange={(e) => onChange({ ...value, name: e.target.value })}
            />
          </div>
          <div className='form-group row'>
            <p className='c-txt-org p-0 mb-1 f-16'>External URL:</p>
            <input
              className='form-control f-16'
              value={value?.value}
              onChange={(e) => onChange({ ...value, value: e.target.value })}
            />
          </div>
          <div className='row mt-3'>
            <div className='col-6 m-auto'>
              <button className='btn btn--org btn--org02' onClick={toggle}>
                OK
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EventExternalURL
