import { useWindowSize } from '_hooks/useWindowSize'
import React from 'react'
import { AiFillShop } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { useToasts } from 'react-toast-notifications'
import Swal from 'sweetalert2'
import { clearCart, clearOrderDetailsByRef } from '../redux/modules/order'

function ButtonAdminConsole({ style }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowSize()
  const { addToast } = useToasts()
  const { cart, orderDetailsByRef } = useSelector((state) => state.order)
  const { myShops } = useSelector((state) => state.shop)

  const onClearCart = () => {
    dispatch(clearOrderDetailsByRef())
    dispatch(clearCart())
  }

  const onDirect = () => {
    const fromMyPurchasePath = location.state?.from === 'my-purchase'
    if (width <= 768) {
      return addToast('You cannot login to admin console on mobile device.', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    const myFirstShop = myShops.length > 0 ? myShops[0] : null

    if ((cart?.orderList?.length > 0 || orderDetailsByRef?.ref) && !fromMyPurchasePath) {
      Swal.fire({
        title: 'Are you sure you want to leave this page?',
        text: 'Currently, you have an order in progress. Do you want to remove the order information?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15cdca',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          onClearCart()
          setTimeout(() => {
            onClearCart()
            history.push(`/admin-console/${myFirstShop?.shopSlug}/setting/general`)
          }, 500)
        }
      })
    } else {
      if (fromMyPurchasePath) {
        onClearCart()
      }

      localStorage.setItem('isAdminConsole', true)
      history.push(`/admin-console/${myFirstShop?.shopSlug}/setting/general`)
    }
  }

  return (
    <button
      className='btn btn-primary float-button w-auto rounded btn-admin-console'
      style={style}
      onClick={onDirect}
    >
      <AiFillShop size={18} className='me-1' />
      <span>Admin Console</span>
    </button>
  )
}

export default ButtonAdminConsole
