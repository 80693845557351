/* eslint-disable function-paren-newline */
import { yupResolver } from '@hookform/resolvers/yup'
import uploadApi from '_api/upload'
import Badge from '_components/Badge'
import NoImage from '_images/noimage02.svg'
import { addProduct } from '_redux/modules/product'
import { scrollToTop } from '_utils/function'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useForm } from 'react-hook-form'
import { FaCamera } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import * as yup from 'yup'

function FormCreateProduct({ toggle }) {
  const defaultValues = {
    image: '',
    name: '',
    quantity: '',
    price: '',
    sellerSku: '',
    category: '',
    width: '',
    height: '',
    weight: '',
    length: '',
    photoUrls: '',
  }
  const [imagePreview, setImagePreview] = useState('')
  const { addToast } = useToasts()
  const dispatch = useDispatch()
  const { selectedShop } = useSelector((state) => state.shop)
  const listProduct = selectedShop?.products
  const { currentPackage } = useSelector((state) => state.subscription)
  const today = moment()
  const isSubscriptionExpired = today.isAfter(moment.unix(currentPackage?.expireTimestamp))

  const formSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    quantity: yup
      .number()
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          const trimmedValue = originalValue.trim()
          if (trimmedValue === '' || trimmedValue === '-') {
            return undefined
          }
          const numberValue = Number(trimmedValue)
          return isNaN(numberValue) ? undefined : numberValue
        }
        return value
      })
      .positive('Quantity must be a positive number')
      .required('Quantity cannot be empty'),
    price: yup
      .number()
      .transform((value, originalValue) => {
        if (typeof originalValue === 'string') {
          const trimmedValue = originalValue.trim()
          if (trimmedValue === '' || trimmedValue === '-') {
            return undefined
          }
          const numberValue = Number(trimmedValue)
          return isNaN(numberValue) ? undefined : numberValue
        }
        return value
      })
      .positive('Price must be a positive number')
      .required('Price cannot be empty'),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues,
  })

  const watchProduct = watch()

  const onUserImageUploaded = async (event) => {
    try {
      const { files } = event.target
      const maxImageSize = 1024 * 1024 // 1MB

      if (files) {
        const img = files[0]

        if (img.size > maxImageSize) {
          addToast('Image size maximum is 1MB', { appearance: 'error', autoDismiss: true })
          return
        }

        if (img) {
          setImagePreview(URL.createObjectURL(img))

          const { msgResp } = await uploadApi.uploadPhoto(files)

          setValue('photoUrls', msgResp?.url)
        } else {
          setImagePreview('')
        }
      }
    } catch (error) {
      addToast(error?.msgResp || error?.message || 'Upload image failed', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const onSubmit = async (data) => {
    try {
      const lastIndex = listProduct?.length - 1
      const newData = {
        products: [
          {
            categoryId: null,
            items: [
              {
                photoUrls: data.photoUrls || null,
                name: data.name,
                description: data.description,
                price: data.price,
                maxQuantity: 1000,
                minOrderQty: 1,
                defaultQuantity: 0,
                availableOfStock: data.quantity,
                limitPerOrder: 1000,
                dateSelectInfo: null,
                index: lastIndex + 1,
                subItems: [],
              },
            ],
          },
        ],
      }

      if (isSubscriptionExpired && listProduct?.length === 5) {
        return addToast('Your package is expired, so you can only create a maximum of 5 products', {
          appearance: 'error',
          autoDismiss: true,
        })
      }

      await dispatch(addProduct(selectedShop, newData))
      addToast('Created product successfully', { appearance: 'success', autoDismiss: true })
      scrollToTop()
    } catch (error) {
      error.msgResp = ''
      addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
    } finally {
      toggle()
    }
  }

  return (
    <form className='form-create-product row' onSubmit={handleSubmit(onSubmit)}>
      {/* upload image */}
      <div className='col-12 bg-light py-3 px-0'>
        <div className='form-upload-image border border-dashed rounded bg-white'>
          <div className='form-upload-image__image'>
            <img src={imagePreview || NoImage} alt='product' className='mx-auto mb-3 rounded' />

            <div className='form-upload-image__image__overlay'>
              <div className='form-upload-image__image__overlay__icon'>
                <FaCamera size={30} />

                <div className='form-upload-image__image__overlay__icon__text'>
                  <input type='file' onChange={onUserImageUploaded} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors?.image && (
          <small className='text-danger text-center d-block mt-2'>{errors?.image?.message}</small>
        )}
      </div>

      {/* product info */}
      <div className='px-3'>
        <div className='border product-info mt-3 rounded p-3 row position-relative'>
          <Badge
            className='w-auto text-white position-absolute rounded'
            type=''
            style={{
              top: '-13px',
              left: '10px',
              backgroundColor: '#f06a0f',
            }}
          >
            Product Info
          </Badge>
          <div className={classNames('form-group col-12')}>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              className='form-control-sm border rounded w-100'
              id='name'
              placeholder='Enter name'
              {...register('name')}
            />
            {errors?.name && <small className='text-danger'>{errors?.name?.message}</small>}
          </div>

          <div className='form-group col-12'>
            <label htmlFor='product-quantity'>Stock Available</label>

            <CurrencyFormat
              id='product-quantity'
              value={watchProduct.quantity || 0}
              displayType={'input'}
              thousandSeparator={true}
              className='form-control-sm border w-100 mb-2'
              onValueChange={(values) => {
                const { value } = values
                setValue('quantity', value)
              }}
              {...register('quantity')}
            />
            {errors?.quantity && <small className='text-danger'>{errors?.quantity?.message}</small>}
          </div>
          <div className='form-group col-12'>
            <label htmlFor='product-price'>Price ($)</label>

            <CurrencyFormat
              id='product-price'
              value={watchProduct.price || 0}
              displayType={'input'}
              thousandSeparator={true}
              className='form-control-sm border w-100 mb-2'
              onValueChange={(values) => {
                const { value } = values
                setValue('price', value)
              }}
              {...register('price')}
            />

            {errors?.price && <small className='text-danger'>{errors?.price?.message}</small>}
          </div>

          <div className='form-group col-12'>
            <label htmlFor='description'>Description</label>
            <textarea
              rows={5}
              className='form-control-sm border rounded w-100'
              id='description'
              placeholder='Enter description'
              {...register('description')}
            />
            {errors?.description && (
              <small className='text-danger'>{errors?.description?.message}</small>
            )}
          </div>
        </div>

        <button type='submit' className='btn btn-submit shadow-none mt-3' disabled={isSubmitting}>
          {isSubmitting ? 'Creating...' : 'Create'}
        </button>
      </div>
    </form>
  )
}

export default FormCreateProduct
