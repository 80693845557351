/* eslint-disable no-constant-condition */
import { ORDER_CO_KII, ORDER_LAZADA, SUBSCRIPTION_STATUS_APPROVED } from '_utils/constant'
import React from 'react'
import { Button, ButtonGroup } from 'reactstrap'

function TabsECommerce({ onSelectTab, tabOrderPlatform, lazadaPackage, isLazadaValid }) {
  return (
    <ButtonGroup>
      <Button
        outline
        onClick={() => onSelectTab(ORDER_CO_KII)}
        active={tabOrderPlatform === ORDER_CO_KII}
      >
        Co-Hoot
      </Button>
      {isLazadaValid && false ? (
        <Button
          outline
          onClick={() => onSelectTab(ORDER_LAZADA)}
          active={tabOrderPlatform === ORDER_LAZADA}
          disabled={lazadaPackage?.status !== SUBSCRIPTION_STATUS_APPROVED}
        >
          Lazada
        </Button>
      ) : null}
    </ButtonGroup>
  )
}

export default TabsECommerce
