import axiosClient from './axiosClient'

const deliveryEventApi = {
  getListDeliveriesCreatedByShop: (shopId) => {
    const url = '/list-shop-event-deliveries-by-shop'
    return axiosClient.get(url, {
      params: {
        shopId,
      },
    })
  },
  getListOrderDeliveryEventByDeliveryId: ({ deliveryId }) => {
    const url = '/get-shop-event-delivery'
    return axiosClient.get(url, {
      params: {
        id: deliveryId,
        getDeliveryBooking: 1,
      },
    })
  },
  createDeliveryZones: (data) => {
    const url = '/create-shop-event-delivery'
    return axiosClient.post(url, data)
  },
  deleteDeliveryZones: (deliveryId) => {
    const url = '/delete-shop-event-delivery'
    return axiosClient.delete(url, {
      params: {
        id: deliveryId,
      },
    })
  },
  updateDeliveryZones: (data, deliveryId) => {
    const url = '/update-shop-event-delivery'
    return axiosClient.patch(url, data, {
      params: {
        id: deliveryId,
      },
    })
  },
  getDeliveryZonesDetail: (deliveryId) => {
    const url = '/get-shop-event-delivery'
    return axiosClient.get(url, {
      params: {
        id: deliveryId,
      },
    })
  },

  updateDeliveryBookingStatus: (ref, data) => {
    const url = '/update-shop-event-delivery-booking-status'
    return axiosClient.patch(url, data, {
      params: {
        ref,
      },
    })
  },
  updateDeliveryBookerStatus: (ref, data) => {
    const endPoint = 'update-shop-event-delivery-booker-status'
    const url = `/${endPoint}?ref=${ref}`
    return axiosClient.patch(url, data)
  },
  getDelivery: (deliveryId, getDeliveryBooking = false) => {
    const url = '/get-shop-event-delivery'
    return axiosClient.get(url, {
      params: {
        id: deliveryId,
        getDeliveryBooking,
      },
    })
  },
  updateRememberedDeliveryZones: (data) => {
    const url = '/update-shop-event-remember-delivery-zones'
    return axiosClient.put(url, data)
  },
  getRememberedDeliveryZones: (shopId) => {
    const url = '/get-shop-event-remembered-delivery-zones'
    return axiosClient.get(url, {
      params: {
        shopId,
      },
    })
  },
}

export default deliveryEventApi
