import { OrderRecordContext } from '_contexts/OrderRecordContext'
import {
  HOST_ORDER_CANCELED,
  HOST_ORDER_FULFILLED,
  HOST_ORDER_PAID,
  HOST_ORDER_UNPAID,
  HOST_ORDER_VERIFIED,
} from '_utils/constant'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import Select from 'react-select'

const optionsOrderShop = [
  { value: HOST_ORDER_UNPAID, label: 'Unpaid' },
  { value: HOST_ORDER_PAID, label: 'Paid' },
  { value: HOST_ORDER_VERIFIED, label: 'Payment Verified' },
  { value: HOST_ORDER_CANCELED, label: 'Cancelled' },
  { value: HOST_ORDER_FULFILLED, label: 'Fulfilled' },
]

function OrderStatus() {
  const { onChangeOrderStatus, filter } = useContext(OrderRecordContext)
  const { orderStatus } = filter

  return (
    <div className='select-status'>
      <Select
        isMulti
        name='colors'
        options={optionsOrderShop}
        value={orderStatus}
        onChange={onChangeOrderStatus}
        className='basic-multi-select'
        classNamePrefix='select'
        placeholder='Order Status'
      />
    </div>
  )
}

OrderStatus.propTypes = {
  getOrderStatus: PropTypes.func,
  orderStatus: PropTypes.array,
}

export default OrderStatus
