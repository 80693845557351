import cancelImage from '_images/cancel.png'
import noImage from '_images/noimage02.svg'
import { useToasts } from 'react-toast-notifications'
import { Modal, ModalBody } from 'reactstrap'

function LogoModal(props) {
  const { modal, toggle, listImage, setListImage } = props
  const { addToast } = useToasts()

  const onUserImageUploaded = (event) => {
    const { files } = event.target
    if (files.length + listImage?.length > 64) {
      addToast('Maximum images for shop is 64!', { appearance: 'error', autoDismiss: true })
      return
    }

    for (let i = 0; i < files.length; i++) {
      if (i < 64) {
        listImage.push({ url: global.URL.createObjectURL(files[i]), file: files[i] })
      }
    }
    setListImage([...listImage])
  }

  const handleOnRemoveImg = (index) => {
    listImage.splice(index, 1)
    setListImage([...listImage])
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered style={{ maxWidth: '840px' }}>
        <ModalBody className='py-0'>
          <div className='add__logo-modal'>
            <p className='add__logo-modal-title'>Upload shop image</p>
            <div className='d-flex justify-content-center w-100'>
              <span className='add-more-image' style={{ color: '#00092C' }}>
                Remain: ({64 - listImage?.length} photos left)
              </span>
            </div>
            <div
              className='c-form-modal upload-image-square mt-3 mb-3 cursor-pointer'
              id='upload-img'
            >
              <div className='upload-image flex-column'>
                <div className='upload-image-txt d-flex flex-column align-items-center position-absolute'>
                  <img src={noImage} alt='' />
                  <span className='text-upload'>Upload Image</span>
                </div>
                <div className='u-file-upload__item'>
                  <div className='u-file-upload__wrap'>
                    <a className='c-upload preview-images-zone'>
                      <input
                        type='file'
                        accept='image/*'
                        className='inputFile'
                        onChange={onUserImageUploaded}
                        readOnly
                        multiple
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='add__logo__modal-list-image'>
              {listImage?.map((item, index) => (
                <div
                  key={index}
                  className='add__logo__modal-list-image-item rounded shadow-sm overflow-hidden'
                >
                  <img src={item.url} alt='' className='logo-img' />
                  <img
                    src={cancelImage}
                    className='btn-remove-image'
                    onClick={() => handleOnRemoveImg(index)}
                  />
                </div>
              ))}
            </div>
            <button className='btn btn-primary shadow-none mt-3' onClick={toggle}>
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default LogoModal
