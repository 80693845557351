/* eslint-disable no-control-regex */
/* eslint-disable no-lonely-if */
import { yupResolver } from '@hookform/resolvers/yup'
import orderApi from '_api/order'
import virtualShopApi from '_api/virtualShop'
import { CheckoutContext } from '_contexts/CheckoutContext'
import {
  clearDelivery,
  clearPickup,
  clearSelectDelivery,
  clearSelectPickUp,
  getListDelivery,
  getListPickup,
  selectDelivery,
  selectFulfillmentType,
  selectPickUp,
} from '_redux/modules/fulfillment'
import {
  BUYER_ORDER_UNPAID,
  DELIVERY_OPTION,
  NO_FULFILLMENT,
  PAYMENT_COD,
  SELF_PICK_UP,
  TRANSFER_BANKING,
} from '_utils/constant'
import { getOrderRefParam, sanitizeStringForUrl, scrollToTop } from '_utils/function'

import LoadingOverlay from '_components/LoadingOverlay'
import ModalNotifySuccess from '_components/ModalNotifySuccess'
import NotFound from '_components/NotFound'
import {
  clearCart,
  clearOrderDetailsByRef,
  getOrderDetailsByRef,
  getOrderDetailsByRefNoLogin,
} from '_redux/modules/order'
import { getShopByShopSlug, setShopDetail } from '_redux/modules/shop'
import { convertToCurrentGMT } from '_utils/functions/converter'
import { getTimeZoneOffset } from '_utils/functions/generator'
import {
  getCartCheckout,
  getCartOrderDetailsByRef,
  getDataShopDetail,
  getUserInfo,
  getUserToken,
  removeCartCheckout,
  setCartOrderDetailsByRef,
} from '_utils/localData'
import classNames from 'classnames'
import { isEmpty, isUndefined } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Spinner } from 'reactstrap'
import Bill from './components/Bill'
import { CheckBoxGift } from './components/CheckBoxGift'
import FormContactDetails from './components/FormContactDetails'
import Tabs from './components/Tabs'
import { createBuyerSchema } from './schemaValidate'
import './style.scss'

// const valueDay = {
//   Sun: 1,
//   Mon: 2,
//   Tue: 3,
//   Wed: 4,
//   Thu: 5,
//   Fri: 6,
//   Sat: 7,
// }

function Checkout() {
  const userInfo = getUserInfo()
  const userToken = getUserToken()
  const cart = getCartCheckout()
  const cartOrderDetailsByRef = getCartOrderDetailsByRef()
  const shopDetailLocalStorage = getDataShopDetail()
  const orderRefParams = getOrderRefParam()

  const [shopNotFound, setShopNotFound] = useState(false)
  const [shopDetailState, setShopDetailState] = useState({})
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(NO_FULFILLMENT)
  const [isOpenTimePicker, setIsOpenTimePicker] = useState(false)
  const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false)

  const { shopDetail } = useSelector((state) => state.shop)

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { dateAndTime, shopSlugBefore, orderDetailsByRef } = useSelector((state) => state.order)
  const { listDelivery, listPickUp } = useSelector((state) => state.fulfillment)

  const isOrderRef =
    orderRefParams && orderDetailsByRef?.ref ? orderRefParams === orderDetailsByRef?.ref : false
  const cartByRef = {
    orderList: orderDetailsByRef?.listProduct?.map((item) => ({
      ...item,
      quantityInput: item.quantity,
    })),
  }

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    clearErrors,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      address: '',
      giftInfo: {},
      isGift: false,
      paymentMethod: TRANSFER_BANKING,
      noLogin: !userToken,
      pickupOption: {},
      deliveryOption: {},
      fulfillmentType: NO_FULFILLMENT,
    },
    resolver: yupResolver(createBuyerSchema(activeTab)),
  })
  const buyer = watch()
  const fulfillmentType = watch('fulfillmentType')
  const watchPickup = watch('pickupOption')
  const watchDelivery = watch('deliveryOption')

  const resetFormValuesGift = () => {
    setValue('giftInfo.name', '')
    setValue('giftInfo.email', '')
    setValue('giftInfo.phone', '')
    setValue('giftInfo.address', '')
  }

  useEffect(() => {
    if (!buyer.isGift) {
      resetFormValuesGift()
    } else {
      setValue('address', '')
    }
  }, [buyer.isGift])

  useEffect(() => {
    if (!isEmpty(shopDetailLocalStorage)) {
      setShopDetailState(shopDetailLocalStorage)
    }
  }, [!isEmpty(shopDetailLocalStorage)])

  // FETCH DATA
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await Promise.all([
        dispatch(getListPickup(shopDetailState?.id)),
        dispatch(getListDelivery(shopDetailState?.id)),
      ])
      setLoading(false)
    }

    if (shopDetailState?.id && shopDetailState?.fulfillmentStatus) {
      fetchData().then(() => {})
    }

    scrollToTop()

    return () => {
      dispatch(clearPickup)
      dispatch(clearDelivery)
    }
  }, [shopDetailState?.id])

  // EFFECT USER CART
  useEffect(() => {
    if ((cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref) && userInfo?.id) {
      dispatch(getOrderDetailsByRef(cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref))
    }

    if (!userInfo?.id && cart?.buyer?.email) {
      dispatch(
        getOrderDetailsByRefNoLogin({
          ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
          uEmail: cart?.buyer?.email,
        })
      )
    }
  }, [cartOrderDetailsByRef?.ref, userInfo?.id, orderDetailsByRef?.ref])

  useEffect(() => {
    let isMounted = true

    if (orderDetailsByRef?.shopId) {
      const fetchFulfillment = async () => {
        setLoading(true)
        await Promise.all([
          dispatch(getListPickup(orderDetailsByRef.shopId)),
          dispatch(getListDelivery(orderDetailsByRef.shopId)),
        ])
        if (isMounted) setLoading(false)
      }

      const fetchShop = async () => {
        try {
          const shopSlugFormatted = sanitizeStringForUrl(orderDetailsByRef.shopName)
          const shopSlugStateFormatted = sanitizeStringForUrl(shopDetailState.shopSlug)

          setLoading(true)
          await dispatch(getShopByShopSlug(shopSlugFormatted || shopSlugStateFormatted))
        } catch (error) {
          if (isMounted) {
            setShopNotFound(true)
            addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
          }
        } finally {
          if (isMounted) setLoading(false)
        }
      }

      fetchShop().then(() => {})
      fetchFulfillment().then(() => {})
    }

    return () => {
      isMounted = false
    }
  }, [orderDetailsByRef?.shopId])

  useEffect(() => {
    if (!isEmpty(shopDetail) && !isEmpty(orderDetailsByRef)) {
      if (orderDetailsByRef.fulfilmentType === NO_FULFILLMENT) {
        const convertedShop = {
          ...shopDetail,
          fulfillmentStatus: false,
        }
        setShopDetailState(convertedShop)
        dispatch(setShopDetail(convertedShop))
      } else {
        setShopDetailState(shopDetail)
      }
    } else if (!isEmpty(shopDetail)) {
      setShopDetailState(shopDetail)
    }
  }, [isEmpty(shopDetail), JSON.stringify(orderDetailsByRef)])

  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      listPickUp.length &&
      activeTab === SELF_PICK_UP &&
      isEmpty(orderDetailsByRef?.orderPickup?.pickupOptionId) &&
      isEmpty(cart?.orderPickup?.pickupOptionId)
    ) {
      dispatch(clearSelectDelivery())
      dispatch(selectFulfillmentType(SELF_PICK_UP))
      setValue('fulfillmentType', SELF_PICK_UP)
      dispatch(selectPickUp(listPickUp[0]))
      setValue('pickupOption', JSON.stringify(listPickUp[0]))
    } else if (
      shopDetailState?.fulfillmentStatus &&
      listDelivery.length &&
      activeTab === DELIVERY_OPTION &&
      isEmpty(orderDetailsByRef?.orderDelivery?.deliveryOptionId) &&
      isEmpty(cart?.orderDelivery?.deliveryOptionId)
    ) {
      dispatch(clearSelectPickUp())
      dispatch(selectFulfillmentType(DELIVERY_OPTION))
      setValue('fulfillmentType', DELIVERY_OPTION)
      dispatch(selectDelivery(listDelivery[0]))
      setValue('deliveryOption', JSON.stringify(listDelivery[0]))
    }

    return () => {
      clearErrors()
    }
  }, [
    activeTab,
    JSON.stringify(listDelivery),
    JSON.stringify(listPickUp),
    isEmpty(orderDetailsByRef),
    isEmpty(cart),
  ])

  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      (orderDetailsByRef?.orderPickup?.pickupOptionId || cart?.orderPickup?.pickupOptionId) &&
      listPickUp.length > 0
    ) {
      const pickupOptionBooked = listPickUp?.find(
        (item) =>
          item.id ===
          (orderDetailsByRef?.orderPickup?.pickupOptionId || cart?.orderPickup?.pickupOptionId)
      )
      if (pickupOptionBooked) {
        dispatch(selectFulfillmentType(SELF_PICK_UP))
        setValue('fulfillmentType', SELF_PICK_UP)
        setValue('pickupOption', JSON.stringify(pickupOptionBooked))
        dispatch(selectPickUp(pickupOptionBooked))
      }
    }
  }, [
    orderDetailsByRef?.orderPickup?.pickupOptionId,
    listPickUp.length,
    isEmpty(cart?.orderPickup),
  ])

  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      (orderDetailsByRef?.orderDelivery?.deliveryOptionId ||
        cart?.orderDelivery?.deliveryOptionId) &&
      listDelivery.length > 0
    ) {
      const deliveryOptionBooked = listDelivery?.find(
        (item) =>
          item?.id ===
          (orderDetailsByRef?.orderDelivery?.deliveryOptionId ||
            cart?.orderDelivery?.deliveryOptionId)
      )
      if (deliveryOptionBooked) {
        dispatch(selectFulfillmentType(DELIVERY_OPTION))
        setValue('fulfillmentType', DELIVERY_OPTION)
        setValue('deliveryOption', JSON.stringify(deliveryOptionBooked))
        dispatch(selectDelivery(deliveryOptionBooked))
      }
    }
  }, [
    orderDetailsByRef?.orderDelivery?.deliveryOptionId,
    listDelivery.length,
    !isEmpty(cart?.orderDelivery),
  ])

  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      (listPickUp.length || listDelivery.length) &&
      !isEmpty(orderDetailsByRef) &&
      [NO_FULFILLMENT, DELIVERY_OPTION, SELF_PICK_UP].includes(orderDetailsByRef?.fulfilmentType)
    ) {
      setActiveTab(orderDetailsByRef?.fulfilmentType)
    }
  }, [JSON.stringify(orderDetailsByRef), JSON.stringify(listDelivery), JSON.stringify(listPickUp)])

  useEffect(() => {
    if (
      (listPickUp.length || listDelivery.length) &&
      isEmpty(orderDetailsByRef?.orderPickup) &&
      isEmpty(orderDetailsByRef?.orderDelivery)
    ) {
      setActiveTab(SELF_PICK_UP)
      dispatch(selectFulfillmentType(SELF_PICK_UP))
    }
  }, [JSON.stringify(listPickUp), JSON.stringify(listDelivery), JSON.stringify(orderDetailsByRef)])
  // END USER CART

  // EFFECT FOR ORDERING
  useEffect(() => {
    if (shopDetailState?.fulfillmentStatus && listPickUp.length && !listDelivery.length) {
      setActiveTab(SELF_PICK_UP)
    }
  }, [JSON.stringify(listPickUp)])

  useEffect(() => {
    if (shopDetailState?.fulfillmentStatus && !listPickUp.length && listDelivery.length) {
      setActiveTab(DELIVERY_OPTION)
    }
  }, [JSON.stringify(listDelivery)])

  useEffect(() => {
    if (shopDetailState?.fulfillmentStatus && activeTab === SELF_PICK_UP) {
      dispatch(clearSelectDelivery())
      dispatch(selectFulfillmentType(SELF_PICK_UP))
      setValue('fulfillmentType', SELF_PICK_UP)
      if (typeof watchPickup === 'string') {
        dispatch(selectPickUp(JSON.parse(watchPickup)))
      }
      setValue('isCheckCOD', false)
      setValue('paymentMethod', TRANSFER_BANKING)
    }

    if (shopDetailState?.fulfillmentStatus && activeTab === DELIVERY_OPTION) {
      dispatch(clearSelectPickUp())
      dispatch(selectFulfillmentType(DELIVERY_OPTION))
      setValue('fulfillmentType', DELIVERY_OPTION)
      if (typeof watchDelivery === 'string') {
        dispatch(selectDelivery(JSON.parse(watchDelivery)))
      }
    }
  }, [activeTab])

  useEffect(() => {
    if (cart?.buyer?.isCheckCOD || orderDetailsByRef?.cashOnDeliveryPickup) {
      setValue('isCheckCOD', true)
      setValue('paymentMethod', PAYMENT_COD)
    }
  }, [JSON.stringify(cart), orderDetailsByRef?.cashOnDeliveryPickup, activeTab])

  // For selecting delivery option when ordering
  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      !isEmpty(watchDelivery) &&
      typeof watchDelivery === 'string'
    ) {
      dispatch(selectDelivery(JSON.parse(watchDelivery)))
    }
  }, [watchDelivery])

  // For selecting pickup option when ordering
  useEffect(() => {
    if (
      shopDetailState?.fulfillmentStatus &&
      !isEmpty(watchPickup) &&
      typeof watchPickup === 'string'
    ) {
      dispatch(selectPickUp(JSON.parse(watchPickup)))
    }
  }, [watchPickup])
  // EFFECT FOR ORDERING

  const handleWhenShowModalPaymentSuccess = (btnType) => {
    if (btnType === 'continue') {
      // yes
      if (!isEmpty(shopDetail)) {
        history.push(`/${shopDetailState?.shopSlug}`)
      } else if (!isEmpty(orderDetailsByRef)) {
        const shopSlug =
          orderDetailsByRef?.shopLink.split('/')[orderDetailsByRef?.shopLink.split('/').length - 1]
        history.push(`/${shopSlug}`)
      } else {
        const shopSlugNoLogin = localStorage.getItem('shopSlugNoLogin')
        history.push(`/${shopSlugNoLogin}`)
        localStorage.removeItem('shopSlugNoLogin')
      }
      dispatch(clearCart())
      dispatch(clearOrderDetailsByRef())
    } else {
      // No
      if (window.confirm('Are you sure you want to exit?')) {
        history.push('/virtual-shop/list')
        dispatch(clearCart())
        dispatch(clearOrderDetailsByRef())
      }
    }
  }

  const getUserIdFromPath = () => {
    const shopLinksOfUser = userInfo?.shopLinks?.find((link) => link?.includes(shopSlugBefore))

    if (shopLinksOfUser) {
      return userInfo?.id
    }

    return undefined
  }

  const handlePaymentBanking = async (postData) => {
    if (isOrderRef) {
      // edit order
      const isChangeFulfillment = orderDetailsByRef?.fulfilmentType !== activeTab
      const convertData = {
        ...postData,
        ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
        changeFulfillment: isChangeFulfillment,
        cashOnDeliveryPickup:
          buyer.fulfillmentType === DELIVERY_OPTION ? postData.cashOnDeliveryPickup : undefined,
      }

      if (userInfo?.id) {
        try {
          await orderApi.updateShopOrderWithFulfillment(JSON.parse(JSON.stringify(convertData)))

          // dispatch(clearCart())
          setValue('isGift', false)
          dispatch(clearOrderDetailsByRef())
          resetFormValuesGift()
          if (cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref) {
            history.push(
              `/virtual-shop/payment-code/${cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref}`,
              {
                from: 'checkout',
              }
            )
          } else {
            history.push('/virtual-shop/payment-code', { from: 'checkout' })
          }
        } catch (error) {
          addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
        }
      } else {
        // do something
      }
    } else {
      // create order
      try {
        if (!userToken) {
          localStorage.setItem('emailNoLogin', buyer.email)
          localStorage.setItem('shopSlugNoLogin', shopDetailState?.shopSlug)
        }

        const { msgResp } = await virtualShopApi.createOrderShopWithFulfillment(
          JSON.parse(JSON.stringify(postData)),
          userToken
        )

        resetFormValuesGift()
        setValue('isGift', false)
        history.push('/virtual-shop/payment-code', { from: 'checkout' })

        await dispatch(clearOrderDetailsByRef())
        setCartOrderDetailsByRef({ ref: msgResp.ref, email: buyer.email })
        dispatch(clearCart())
        dispatch(clearSelectDelivery())
        dispatch(clearSelectPickUp())

        if (!userToken) removeCartCheckout()
      } catch (error) {
        addToast(error?.message || error?.msgResp || 'Error unknown', {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }

  const handlePaymentCOD = async (postData) => {
    if (isOrderRef) {
      const isChangeFulfillment = orderDetailsByRef?.fulfilmentType !== activeTab
      try {
        const convertData = {
          ...postData,
          ref: cartOrderDetailsByRef?.ref || orderDetailsByRef?.ref,
          changeFulfillment: isChangeFulfillment,
        }
        await orderApi.updateShopOrderWithFulfillment(JSON.parse(JSON.stringify(convertData)))

        setValue('isGift', false)
        resetFormValuesGift()

        if (isChangeFulfillment) {
          history.push('/virtual-shop/payment-code', { from: 'checkout' })
        } else {
          history.push('/virtual-shop/my-purchase')
        }

        dispatch(clearOrderDetailsByRef())
        dispatch(clearCart())
        dispatch(clearSelectDelivery())
        dispatch(clearSelectPickUp())

        addToast('Updated order successfully', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (error) {
        addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    } else {
      try {
        if (!userToken) {
          localStorage.setItem('emailNoLogin', buyer.email)
          localStorage.setItem('shopSlugNoLogin', shopDetailState?.shopSlug)
        }

        const { msgResp } = await virtualShopApi.createOrderShopWithFulfillment(
          JSON.parse(JSON.stringify(postData)),
          userToken
        )
        setValue('isGift', false)
        resetFormValuesGift()
        dispatch(clearOrderDetailsByRef())
        dispatch(clearSelectDelivery())
        dispatch(clearSelectPickUp())
        setCartOrderDetailsByRef({ ref: msgResp.ref, email: buyer.email })
        setIsModalOpenSuccess(true)
        if (!userToken) removeCartCheckout()
      } catch (error) {
        addToast(error.message || error.msgResp, { appearance: 'error', autoDismiss: true })
      }
    }
  }

  const parseJSONIfPossible = (value) => {
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }

  const renderGiftInfo = (data) => {
    if (!isEmpty(orderDetailsByRef?.giftRecipientInfo)) {
      return !buyer.isGift ? null : data.giftInfo
    }

    if (fulfillmentType === DELIVERY_OPTION) {
      return buyer.isGift ? data.giftInfo : undefined
    }

    return undefined
  }

  const onSubmit = async (data) => {
    if (cart?.orderList?.length) {
      data.details = cart?.orderList
        .filter((item) => item.quantityInput)
        .reduce((acc, item) => {
          const { id, quantityInput, subItems } = item
          acc[id] = acc[id] || []
          acc[id].push({ quantity: quantityInput, subItems })
          return acc
        }, {})
    }

    data.pickupOption = !isEmpty(data.pickupOption) ? parseJSONIfPossible(data.pickupOption) : {}
    data.deliveryOption = !isEmpty(data.deliveryOption)
      ? parseJSONIfPossible(data.deliveryOption)
      : {}

    if (shopDetailState?.id) {
      const postData = {
        details: data.details,
        fullName: data.name,
        shopId: shopDetailState?.id,
        cashOnDeliveryPickup: shopDetailState?.cashOnDeliveryPickup
          ? buyer.paymentMethod === PAYMENT_COD
            ? 1 // payment method COD
            : undefined
          : undefined,
        representedUserId: getUserIdFromPath(),
        email: data.email,
        mobilePhone: data.phone,
        address: !buyer.isGift ? data.address || undefined : undefined,
        buyerStatus: BUYER_ORDER_UNPAID,
        postalCode: 12345, // test
        pickupOptionId: fulfillmentType === SELF_PICK_UP ? data.pickupOption.id : undefined,
        pickupBookingDate: fulfillmentType === SELF_PICK_UP ? dateAndTime : undefined,
        pickupBookingTime: fulfillmentType === SELF_PICK_UP ? dateAndTime.time : undefined,
        ref: Math.random().toString(36).substring(6) + new Date().getTime().toString(),
        giftRecipientInfo: renderGiftInfo(data),
        deliveryOptionId: fulfillmentType === DELIVERY_OPTION ? data.deliveryOption.id : undefined,
        deliveryBookingDate: fulfillmentType === DELIVERY_OPTION ? dateAndTime : undefined,
        deliveryBookingTime: fulfillmentType === DELIVERY_OPTION ? dateAndTime.time : undefined,
        gmt: getTimeZoneOffset(),
      }

      const hasRangeTime = dateAndTime?.time?.includes('-')
      const endTime = hasRangeTime ? dateAndTime?.time?.split('-')[1] : dateAndTime?.time

      // GMT+0
      // const endTime = dateAndTime?.time ? dateAndTime?.time?.split('-')[1] : undefined

      const currentEndTime = convertToCurrentGMT(`${dateAndTime?.date} ${endTime}`)

      const endUnixTime = moment(currentEndTime, 'DD-MM-YYYY HH:mm').unix()

      const today = moment().unix()
      const selectedDate = moment(dateAndTime?.date, 'DD-MM-YYYY')

      if (
        fulfillmentType !== NO_FULFILLMENT &&
        (selectedDate.isBefore(moment(), 'day') ||
          (today > endUnixTime && moment().format('DD-MM-YYYY') === dateAndTime?.date))
      ) {
        scrollToTop()
        addToast('You cannot order for days or range time in the past.', {
          autoDismiss: true,
          appearance: 'error',
        })
        return
      }

      if (today <= endUnixTime || buyer.fulfillmentType === NO_FULFILLMENT) {
        // For remove undefined key
        // JSON.parse(JSON.stringify(postData))
        if (buyer.paymentMethod === PAYMENT_COD) {
          await handlePaymentCOD(postData)
        } else if (buyer.paymentMethod === TRANSFER_BANKING) {
          await handlePaymentBanking(postData)
        }
      }
    }
  }

  const handleDirectBack = () => {
    if (isOrderRef) return

    if (cart?.shopSlug) {
      reset()
      history.push(`/${cart?.shopSlug}`)
    } else if (isOrderRef && !isEmpty(orderDetailsByRef)) {
      const shopSlug =
        orderDetailsByRef?.shopLink?.split('/')[orderDetailsByRef?.shopLink?.split('/')?.length - 1]
      reset()
      history.push(`/${shopSlug}`)
    } else {
      reset()
      history.goBack()
    }

    dispatch(clearOrderDetailsByRef())
  }

  const renderTabDeliveryPickup = () => {
    const isDeliveryOrderPickup = listDelivery?.length > 0 || listPickUp?.length > 0
    const isEditingNoFulfillOrder =
      isOrderRef &&
      orderDetailsByRef !== null &&
      orderDetailsByRef?.fulfilmentType === NO_FULFILLMENT

    if (isEditingNoFulfillOrder) {
      return
    }

    if (loading) {
      return <LoadingOverlay style={{ margin: '30px auto ' }} />
    }

    if (shopDetailState?.fulfillmentStatus && isDeliveryOrderPickup) {
      return (
        <>
          <div className='d-flex align-items-center justify-content-between mb-2'>
            <span className='title text-uppercase'>Delivery Details</span>
          </div>
          <div
            className={classNames(
              'bg-white p-3 mb-3 rounded',
              shopDetailState?.giftRecipient ? '' : 'mb-3'
            )}
          >
            <Tabs />
          </div>
        </>
      )
    }

    return null
  }

  if (orderRefParams && shopNotFound) return <NotFound />

  return (
    <div className='container'>
      <CheckoutContext.Provider
        value={{
          buyer,
          cart,
          register,
          errors,
          userInfo,
          activeTab,
          setActiveTab,
          dispatch,
          shop: shopDetailState,
          setValue,
          isOpenTimePicker,
          setIsOpenTimePicker,
          watch,
          control,
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className='checkout px-3 mt-0'
          style={!userInfo?.shopLinks?.length ? { marginTop: 0 } : null}
        >
          {renderTabDeliveryPickup()}

          {shopDetailState?.giftRecipient && fulfillmentType === DELIVERY_OPTION ? (
            <CheckBoxGift />
          ) : null}

          <FormContactDetails />

          {isUndefined(cartByRef?.orderList) ? <Bill cart={cart} /> : <Bill cart={cartByRef} />}

          <div className='row mt-3'>
            <div className='col-6'>
              <button
                type={'button'}
                onClick={handleDirectBack}
                disabled={isOrderRef}
                className={`btn btn-primary-outline shadow-none text-uppercase text-decoration-none ${
                  isOrderRef && 'disabled'
                }`}
                style={{ border: '2px solid #0badad' }}
              >
                Back
              </button>
            </div>
            <div className='col-6'>
              <button
                type={'submit'}
                className='btn btn-primary rounded-pill shadow-none text-uppercase'
              >
                {isSubmitting ? (
                  <Spinner color='light' size='sm' />
                ) : isOrderRef ? (
                  'Update Order'
                ) : buyer.paymentMethod === PAYMENT_COD ? (
                  'Make Order'
                ) : (
                  'Make Payment'
                )}
              </button>
            </div>
          </div>
        </form>

        <ModalNotifySuccess
          isModalOpen={isModalOpenSuccess}
          handleWhenShowModalPaymentSuccess={handleWhenShowModalPaymentSuccess}
          isCOD={buyer.paymentMethod === PAYMENT_COD}
        />
      </CheckoutContext.Provider>
    </div>
  )
}

export default Checkout
