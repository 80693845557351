/* eslint-disable no-confusing-arrow */
import deliveryEventApi from '_api/deliveryEvent'

const GET_LIST_DELIVERY_EVENT_BY_SHOP = 'GET_LIST_DELIVERY_EVENT_BY_SHOP'
const GET_LIST_ORDER_DELIVERY_EVENT_BY_ID = 'GET_LIST_ORDER_DELIVERY_EVENT_BY_ID'
const CREATE_DELIVERY_ZONES = 'CREATE_DELIVERY_ZONES'
const GET_LIST_DELIVERY_ZONES_CREATED = 'GET_LIST_DELIVERY_ZONES_CREATED'
const UPDATE_DELIVERY_ZONES = 'UPDATE_DELIVERY_ZONES'
const CLEAR_DELIVERY_CREATED_BY_SHOP = 'CLEAR_DELIVERY_CREATED_BY_SHOP'
const DELETE_DELIVERY_ZONES = 'DELETE_DELIVERY_ZONES'
const CLEAR_LIST_ORDER_DELIVERY_EVENT_BY_ID = 'CLEAR_LIST_ORDER_DELIVERY_EVENT_BY_ID'
const UPDATE_STATUS_DELIVERY_BOOKING = 'UPDATE_STATUS_DELIVERY_BOOKING'
const UPDATE_NEW_LIST_ORDER_DELIVERY_EVENT_BY_ID = 'UPDATE_NEW_LIST_ORDER_DELIVERY_EVENT_BY_ID'
const GET_DELIVERY_AND_DELIVERY_BOOKING = 'GET_DELIVERY_AND_DELIVERY_BOOKING'
const GET_REMEMBERED_DELIVERY_ZONES = 'GET_REMEMBERED_DELIVERY_ZONES'
const UPDATE_REMEMBERED_DELIVERY_ZONES = 'UPDATE_REMEMBERED_DELIVERY_ZONES'
const GET_REMEMBERED_DELIVERY_ZONES_START = 'GET_REMEMBERED_DELIVERY_ZONES_START'
const GET_REMEMBERED_DELIVERY_ZONES_END = 'GET_REMEMBERED_DELIVERY_ZONES_END'
const UPDATE_REMEMBERED_DELIVERY_ZONES_START = 'UPDATE_REMEMBERED_DELIVERY_ZONES_START'
const UPDATE_REMEMBERED_DELIVERY_ZONES_END = 'UPDATE_REMEMBERED_DELIVERY_ZONES_END'

const initialState = {
  listDeliveryEventByShop: [],
  listCreatedDeliveryZones: [],
  listDeliveryAndDeliveryBooking: [],
  currentDeliveryAndDeliveryBooking: {},
  rememberedDeliveryZones: null,
  rememberedDeliveryZonesCache: null,
  lastShopId: null,
  errorCreateDeliveryZones: null,
  errorUpdateDeliveryZones: null,
  errorListDeliveryEventByShop: null,
  errorListOrderDeliveryEventById: null,
  errorListDeliveryZonesCreated: null,
  errorDeleteDeliveryZones: null,
  errorUpdateStatusDeliveryBooking: null,
  loadingListDeliveryEventByShop: false,
  loadingCurrentDeliveryAndBooking: false,
  loadingGetListDeliverZonesCreated: false,
  loadingCreateDeliveryZones: false,
  loadingUpdateDeliveryZones: false,
  loadingDeleteDeliveryZones: false,
  loadingUpdateStatusDeliveryBooking: false,
  loadingGetListOrderDeliveryCombinedPayment: false,
  loadingGetRememberedDeliveryZones: false,
  loadingUpdateRememberedDeliveryZones: false,
}

export const updateRememberedDeliveryZones = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_REMEMBERED_DELIVERY_ZONES_START,
      payload: {
        loadingUpdateRememberedDeliveryZones: true,
      },
    })
    await deliveryEventApi.updateRememberedDeliveryZones(data)
    dispatch({
      type: UPDATE_REMEMBERED_DELIVERY_ZONES,
      payload: {
        rememberedDeliveryZones: data.rememberedDeliveryZones,
        rememberedDeliveryZonesCache: data.rememberedDeliveryZones,
      },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_REMEMBERED_DELIVERY_ZONES,
      payload: {
        errorRememberedDeliveryZones: error,
      },
    })
  } finally {
    dispatch({
      type: UPDATE_REMEMBERED_DELIVERY_ZONES_END,
      payload: {
        loadingUpdateRememberedDeliveryZones: false,
      },
    })
  }
}

export const getRememberedDeliveryZones = (shopId) => async (dispatch, getState) => {
  const { deliveryEvent } = getState()
  const { rememberedDeliveryZonesCache, lastShopId } = deliveryEvent

  const shopIdChanged = lastShopId !== shopId

  if (rememberedDeliveryZonesCache && !shopIdChanged) {
    dispatch({
      type: GET_REMEMBERED_DELIVERY_ZONES,
      payload: {
        rememberedDeliveryZones: rememberedDeliveryZonesCache,
      },
    })
    return
  }

  try {
    dispatch({
      type: GET_REMEMBERED_DELIVERY_ZONES_START,
      payload: {
        loadingGetRememberedDeliveryZones: true,
      },
    })
    const { msgResp } = await deliveryEventApi.getRememberedDeliveryZones(shopId)
    dispatch({
      type: GET_REMEMBERED_DELIVERY_ZONES,
      payload: {
        rememberedDeliveryZones: msgResp,
        rememberedDeliveryZonesCache: msgResp,
        lastShopId: shopId,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_REMEMBERED_DELIVERY_ZONES,
      payload: {
        errorRememberedDeliveryZones: error,
      },
    })
  } finally {
    dispatch({
      type: GET_REMEMBERED_DELIVERY_ZONES_END,
      payload: {
        loadingGetRememberedDeliveryZones: false,
      },
    })
  }
}

export const getListDeliveryAndDeliveryBooking = (deliveryId) => async (dispatch, getState) => {
  try {
    const { deliveryEvent } = getState()
    let { listDeliveryAndDeliveryBooking = [] } = deliveryEvent

    const index = listDeliveryAndDeliveryBooking.findIndex((item) => item.id === deliveryId)

    let newDeliveryAndDeliveryBooking = {}
    const { msgResp: deliveryAndDeliveryBooking } = await deliveryEventApi.getDelivery(
      deliveryId,
      true
    )

    if (Object.keys(deliveryAndDeliveryBooking).length) {
      newDeliveryAndDeliveryBooking = { ...deliveryAndDeliveryBooking }
      if (index !== -1) {
        listDeliveryAndDeliveryBooking[index] = newDeliveryAndDeliveryBooking
      } else {
        listDeliveryAndDeliveryBooking = [
          ...listDeliveryAndDeliveryBooking,
          newDeliveryAndDeliveryBooking,
        ]
      }
    }

    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        listDeliveryAndDeliveryBooking,
        currentDeliveryAndDeliveryBooking: newDeliveryAndDeliveryBooking,
        errorDeliveryAndDeliveryBooking: null,
        callNewAPIListDelivery: false,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_DELIVERY_AND_DELIVERY_BOOKING,
      payload: {
        errorDeliveryAndDeliveryBooking: error,
        callNewAPIListDelivery: false,
      },
    })
  }
}

export const updateNewListOrderDeliveryEventById = (newList) => (dispatch) => {
  dispatch({
    type: UPDATE_NEW_LIST_ORDER_DELIVERY_EVENT_BY_ID,
    payload: {
      listOrderDeliveryEventById: newList,
    },
  })
}

export const updateStatusDeliveryBooking = (ref, data) => (dispatch, getState) => {
  try {
    const { deliveryEvent } = getState()
    const { currentDeliveryAndDeliveryBooking } = deliveryEvent

    const { shopEventDeliveryBookings = [] } = currentDeliveryAndDeliveryBooking

    const updateList = shopEventDeliveryBookings.map((order) => {
      if (order?.combinedPaymentRef === ref || order.ref === ref) {
        return {
          ...order,
          ...data,
        }
      }
      return order
    })

    dispatch({
      type: UPDATE_STATUS_DELIVERY_BOOKING,
      payload: {
        currentDeliveryAndDeliveryBooking: {
          ...currentDeliveryAndDeliveryBooking,
          shopEventDeliveryBookings: updateList,
        },
        errorUpdateStatusDeliveryBooking: null,
      },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_STATUS_DELIVERY_BOOKING,
      payload: {
        errorUpdateStatusDeliveryBooking: error,
      },
    })
  }
}

export const deleteDeliveryZone = (deliveryId) => async (dispatch, getState) => {
  const { deliveryEvent } = getState()
  const { listCreatedDeliveryZones } = deliveryEvent

  dispatch({
    type: DELETE_DELIVERY_ZONES,
    payload: {
      loadingDeleteDeliveryZones: true,
    },
  })

  try {
    await deliveryEventApi.deleteDeliveryZones(deliveryId)

    const updateList = listCreatedDeliveryZones.filter((zone) => zone.id !== deliveryId)

    dispatch({
      type: DELETE_DELIVERY_ZONES,
      payload: {
        listCreatedDeliveryZones: updateList,
        errorDeleteDeliveryZones: null,
      },
    })
  } catch (error) {
    dispatch({
      type: DELETE_DELIVERY_ZONES,
      payload: {
        errorDeleteDeliveryZones: error,
      },
    })
    throw new Error(error.msgResp || error.message)
  } finally {
    dispatch({
      type: DELETE_DELIVERY_ZONES,
      payload: {
        loadingDeleteDeliveryZones: false,
      },
    })
  }
}

export const clearDeliveryCreatedByShop = () => (dispatch) => {
  dispatch({
    type: CLEAR_DELIVERY_CREATED_BY_SHOP,
    payload: {
      listDeliveryEventByShop: [],
    },
  })
}

export const getListDeliveriesCreatedByShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LIST_DELIVERY_ZONES_CREATED,
      payload: {
        loadingGetListDeliverZonesCreated: true,
      },
    })

    const { msgResp } = await deliveryEventApi.getListDeliveriesCreatedByShop(shopId)
    dispatch({
      type: GET_LIST_DELIVERY_ZONES_CREATED,
      payload: {
        listCreatedDeliveryZones: msgResp,
        errorListDeliveryZonesCreated: null,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_DELIVERY_ZONES_CREATED,
      payload: {
        errorListDeliveryZonesCreated: error,
      },
    })
  } finally {
    dispatch({
      type: GET_LIST_DELIVERY_ZONES_CREATED,
      payload: {
        loadingGetListDeliverZonesCreated: false,
      },
    })
  }
}

export const createDeliveryZones = (data) => async (dispatch, getState) => {
  const { deliveryEvent } = getState()
  const { listCreatedDeliveryZones } = deliveryEvent

  try {
    dispatch({
      type: CREATE_DELIVERY_ZONES,
      payload: {
        loadingCreateDeliveryZones: true,
      },
    })

    const { msgResp } = await deliveryEventApi.createDeliveryZones(data)

    const updateList = listCreatedDeliveryZones.concat(msgResp)

    dispatch({
      type: CREATE_DELIVERY_ZONES,
      payload: {
        listCreatedDeliveryZones: updateList,
        errorCreateDeliveryZones: null,
      },
    })
  } catch (error) {
    dispatch({
      type: CREATE_DELIVERY_ZONES,
      payload: {
        errorCreateDeliveryZones: error,
      },
    })
    throw new Error(error.msgResp || error.message)
  } finally {
    dispatch({
      type: CREATE_DELIVERY_ZONES,
      payload: {
        loadingCreateDeliveryZones: false,
      },
    })
  }
}

export const updateDeliveryZones = (data, deliveryId) => async (dispatch, getState) => {
  const { deliveryEvent } = getState()
  const { listCreatedDeliveryZones } = deliveryEvent

  try {
    dispatch({
      type: UPDATE_DELIVERY_ZONES,
      payload: {
        loadingUpdateDeliveryZones: true,
      },
    })

    await deliveryEventApi.updateDeliveryZones(data, deliveryId)

    const updateList = listCreatedDeliveryZones.map((zone) => {
      if (zone.id === deliveryId) {
        return {
          ...zone,
          ...data,
        }
      }
      return zone
    })

    dispatch({
      type: UPDATE_DELIVERY_ZONES,
      payload: {
        listCreatedDeliveryZones: updateList,
        errorUpdateDeliveryZones: null,
      },
    })
  } catch (error) {
    dispatch({
      type: UPDATE_DELIVERY_ZONES,
      payload: {
        errorUpdateDeliveryZones: error,
      },
    })
    throw new Error(error.msgResp || error.message)
  } finally {
    dispatch({
      type: UPDATE_DELIVERY_ZONES,
      payload: {
        loadingUpdateDeliveryZones: false,
      },
    })
  }
}

export const clearListOrderDeliveryEventById = () => (dispatch) => {
  dispatch({
    type: CLEAR_LIST_ORDER_DELIVERY_EVENT_BY_ID,
    payload: {
      listOrderDeliveryEventById: [],
    },
  })
}

export const getListOrderDeliveryEventById = (deliveryId) => async (dispatch) => {
  dispatch({
    type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
    payload: {
      loadingCurrentDeliveryAndBooking: true,
      listOrderDeliveryEventById: [],
    },
  })

  try {
    const { msgResp } = await deliveryEventApi.getListOrderDeliveryEventByDeliveryId({ deliveryId })
    dispatch({
      type: GET_LIST_ORDER_DELIVERY_EVENT_BY_ID,
      payload: {
        listOrderDeliveryEventById: msgResp.shopEventDeliveryBookings,
        errorListOrderDeliveryEventById: null,
      },
    })
  } catch (error) {
    dispatch({
      type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
      payload: {
        errorListOrderDeliveryEventById: error,
      },
    })
  } finally {
    dispatch({
      type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
      payload: {
        loadingCurrentDeliveryAndBooking: false,
      },
    })
  }
}

export const getListDeliveryEventByShop =
  ({ shopId }) =>
  async (dispatch) => {
    try {
      const { msgResp } = await deliveryEventApi.getListDeliveriesCreatedByShop(shopId)
      dispatch({
        type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
        payload: {
          listDeliveryEventByShop: msgResp,
          errorListDeliveryEventByShop: null,
        },
      })
    } catch (error) {
      dispatch({
        type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
        payload: {
          errorListDeliveryEventByShop: error,
        },
      })
      throw new Error(error.msgResp || error.message)
    } finally {
      dispatch({
        type: GET_LIST_DELIVERY_EVENT_BY_SHOP,
        payload: {
          loadingListDeliveryEventByShop: false,
        },
      })
    }
  }

const deliveryEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REMEMBERED_DELIVERY_ZONES_START:
    case GET_REMEMBERED_DELIVERY_ZONES_END:
    case UPDATE_REMEMBERED_DELIVERY_ZONES_START:
    case UPDATE_REMEMBERED_DELIVERY_ZONES_END:
    case GET_REMEMBERED_DELIVERY_ZONES:
    case UPDATE_REMEMBERED_DELIVERY_ZONES:
    case GET_DELIVERY_AND_DELIVERY_BOOKING:
    case UPDATE_NEW_LIST_ORDER_DELIVERY_EVENT_BY_ID:
    case UPDATE_STATUS_DELIVERY_BOOKING:
    case CLEAR_LIST_ORDER_DELIVERY_EVENT_BY_ID:
    case DELETE_DELIVERY_ZONES:
    case CLEAR_DELIVERY_CREATED_BY_SHOP:
    case UPDATE_DELIVERY_ZONES:
    case GET_LIST_DELIVERY_ZONES_CREATED:
    case CREATE_DELIVERY_ZONES:
    case GET_LIST_ORDER_DELIVERY_EVENT_BY_ID:
    case GET_LIST_DELIVERY_EVENT_BY_SHOP:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default deliveryEventReducer
