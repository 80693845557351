import { PLATFORM_LAZADA } from '_utils/constant'
import React from 'react'
import { useSelector } from 'react-redux'
import ListProduct from '../CoKii/ListProduct'
import ListProductLazada from '../Lazada/ListProductLazada'

function RenderListProduct({ listProductFilter, searchString, loading, lazadaProps, loadingShop }) {
  const { tabSelected } = useSelector((state) => state.product)

  if (tabSelected === PLATFORM_LAZADA) {
    return (
      <ListProductLazada lazadaProps={lazadaProps} searchString={searchString} loading={loading} />
    )
  }

  return <ListProduct listProductFilter={listProductFilter} loadingShop={loadingShop} />
}

export default RenderListProduct
