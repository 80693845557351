import LoadingPopup from '_components/LoadingPopup'
import ModalGeneralPayment from '_components/ModalGeneralPayment/index'
import { fetchListPackageByType, selectPackage } from '_redux/modules/package'
import {
  fetchListProductLazada,
  fetchListVariationProductLazada,
  selectTab,
  updateNewListProduct,
} from '_redux/modules/product'
import {
  PACKAGE_LAZADA,
  PLATFORM_CO_KII,
  PLATFORM_LAZADA,
  SUBSCRIPTION_STATUS_APPROVED,
} from '_utils/constant'
import { exportToCsv, flexibleSearch, normalizeName } from '_utils/function'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getShopDetailByHost } from '_redux/modules/shop'

import RenderButtonLazada from './components/Common/RenderButtonLazada'
import RenderListProduct from './components/Common/RenderListProduct'
import SearchProduct from './components/Common/SearchProduct'
import TabsECommerce from './components/Common/TabsECommerce'
import './style.scss'

function ManageProducts() {
  const dispatch = useDispatch()

  const [nextStep, setNextStep] = useState(1)
  const [searchString, setSearchString] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const timerRef = useRef(null)

  // State For Lazada
  const [isPushingLaz, setIsPushingLaz] = useState(false)
  const [isRemovingLaz, setIsRemovingLaz] = useState(false)
  const [isUpdatingVariationLaz, setIsUpdatingVariationLaz] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingShop, setLoadingShop] = useState(true)

  const { selectedShop } = useSelector((state) => state.shop)
  const { listProduct, listProductLazada } = useSelector((state) => state.product)
  const { listPackageLazada } = useSelector((state) => state.package)
  const { listInvoiceLazada } = useSelector((state) => state.lazada)

  const isHasLazada =
    listInvoiceLazada?.find((item) => item.shopId === selectedShop?.id)?.status ===
      SUBSCRIPTION_STATUS_APPROVED || false

  const selectedTabProduct = localStorage.getItem('selectedTabProduct')

  // to handle upgrade Lazada features
  useEffect(() => {
    dispatch(fetchListPackageByType(PACKAGE_LAZADA))

    if (selectedShop?.id && selectedShop?.id !== '') {
      const fetchData = async () => {
        setLoadingShop(true)
        await dispatch(getShopDetailByHost(selectedShop.id))
        setLoadingShop(false)
      }
      fetchData()
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedTabProduct) {
      if (Number(selectedTabProduct) === PLATFORM_CO_KII) {
        dispatch(selectTab(PLATFORM_CO_KII))
      }

      if (Number(selectedTabProduct) === PLATFORM_LAZADA && isHasLazada) {
        dispatch(selectTab(PLATFORM_LAZADA))
      }
    } else {
      dispatch(selectTab(PLATFORM_CO_KII))
    }

    setSearchString('')
  }, [selectedTabProduct, isHasLazada])

  useEffect(() => {
    if (
      selectedShop?.id &&
      selectedShop?.id !== '' &&
      Number(selectedTabProduct) === PLATFORM_LAZADA
    ) {
      const fetchData = async () => {
        await dispatch(fetchListProductLazada(selectedShop.id))
        setLoading(false)
      }
      if (isHasLazada) {
        fetchData()
      } else {
        localStorage.removeItem('selectedTabProduct')
        dispatch(selectTab(PLATFORM_CO_KII))
        setLoading(false)
      }
    }
  }, [selectedShop?.id, isHasLazada, selectedTabProduct])

  useEffect(() => {
    if (listProductLazada?.length) {
      const hasVariations = listProductLazada.some((item) => item.variations.length)

      if (hasVariations) {
        dispatch(fetchListVariationProductLazada())
      }

      localStorage.setItem('listProductLazada', JSON.stringify(listProductLazada))
    } else {
      localStorage.removeItem('listProductLazada')
    }
  }, [listProductLazada?.length, isHasLazada, selectedTabProduct])

  const toggleModal = () => setIsOpenModal(!isOpenModal)

  const handleSearchProduct = (value) => {
    setSearchString(value)
  }

  const handleExport = (shopDetail, _listProduct) => {
    const generalInfo = [
      ['Shop Name', shopDetail?.shopName],
      ['Shop Link', shopDetail?.shopLink],
      ['Headline', shopDetail?.headline],
      ['Shop Owner', shopDetail?.createdUserName],
      ['Contact', shopDetail?.createdUserPhone],
      [''],
      [''],
    ]
    const rowsOfProduct = new Array(_listProduct.length) || []
    for (let index = 0; index < _listProduct.length; index++) {
      const product = _listProduct[index]
      const { name, price, availableOfStock, subItems } = product

      const subItemsString = subItems
        .map((subItem) => subItem.list.map((item) => `${item.name} ($${item.price})`).join(', '))
        .join(', ')

      rowsOfProduct[index] = [index + 1, name, `$${price}`, availableOfStock, subItemsString]
    }
    const rows = [
      ...generalInfo,
      ['Product No', 'Product name', 'Price', 'Available Stock', 'Sub Items & Price'],
      ...rowsOfProduct,
    ]
    const fileName = normalizeName(
      `${shopDetail?.shopName} shop export Product by ${moment().format('DD-MM-YYYY')}`
    )
    exportToCsv(`${fileName}.csv`, rows)
  }

  const onUpgradeLazada = () => {
    if (listPackageLazada.length) {
      dispatch(selectPackage(listPackageLazada[0]))
    }

    toggleModal()
  }

  const listProductFilter = useMemo(() => {
    if (searchString) {
      const newList = listProduct?.filter((product) => flexibleSearch(product.name, searchString))
      return newList
    }
    return listProduct
  }, [searchString, JSON.stringify(listProduct)])

  const updateCopiedStatus = useCallback(
    (products) => {
      const updatedProducts = products.map((product) => ({
        ...product,
        isCopied: false,
      }))

      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      timerRef.current = setTimeout(() => {
        dispatch(updateNewListProduct(updatedProducts))
      }, 1000)

      return products // Trả về danh sách sản phẩm gốc
    },
    [dispatch]
  )

  useEffect(() => {
    if (listProductFilter) {
      dispatch(updateNewListProduct(updateCopiedStatus(listProduct)))
    }
  }, [JSON.stringify(listProduct), dispatch, updateCopiedStatus])

  const isLoadingLaz = isPushingLaz || isRemovingLaz || isUpdatingVariationLaz

  const lazadaProps = {
    isPushingLaz,
    setIsPushingLaz,
    isRemovingLaz,
    setIsRemovingLaz,
    isUpdatingVariationLaz,
    setIsUpdatingVariationLaz,
  }

  return (
    <div className='manage-products px-3'>
      {isLoadingLaz && <LoadingPopup />}
      <h3 className='title mb-3'>Products</h3>
      <div className='w-50'>
        <SearchProduct
          searchString={searchString}
          onSearch={handleSearchProduct}
          placeholder='Search name of product'
        />
      </div>

      <div className='d-flex align-items-center justify-content-end mb-3'>
        {false && <TabsECommerce />}

        <div className='d-flex align-items-center'>
          {false && <RenderButtonLazada onUpgradeLazada={onUpgradeLazada} className='d-none' />}

          <button
            onClick={() => handleExport(selectedShop, listProduct)}
            className='bg-transparent text-uppercase border-0
     ms-3 mt-0 shadow-none w-auto rounded btn btn-export px-0'
          >
            Export
          </button>
        </div>
      </div>

      <RenderListProduct
        listProductFilter={listProductFilter}
        searchString={searchString}
        loading={loading}
        loadingShop={loadingShop}
        lazadaProps={lazadaProps}
      />

      {isOpenModal && (
        <ModalGeneralPayment
          isOpenModal={isOpenModal}
          toggleModal={toggleModal}
          nextStep={nextStep}
          setNextStep={setNextStep}
        />
      )}
    </div>
  )
}

export default ManageProducts
