import { OrderRecordContext } from '_contexts/OrderRecordContext'
import {
  PACKAGE_BASIC,
  PLATFORM_CO_KII,
  PLATFORM_LAZADA,
  SUBSCRIPTION_STATUS_UNPAID,
} from '_utils/constant'
import { getEventsByShop } from '_utils/localData'
import { isEmpty } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { AiTwotoneFilter } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import RenderSelectingOrderDate from './RenderSelectingOrderDate'
import Search from './Search'
import SelectFilter from './SelectFilter'
import TabEvent from './TabEvent'
import TabsECommerce from './TabsECommerce'

const groupEvents = (events, groupSize) => {
  const groups = []
  for (let i = 0; i < events.length; i += groupSize) {
    groups.push(events.slice(i, i + groupSize))
  }
  return groups
}

function OrderManagementHeader() {
  const history = useHistory()
  const { selectedShop } = useSelector((state) => state.shop)
  const { lazadaPackage } = useSelector((state) => state.lazada)
  const { listInvoiceSubscription } = useSelector((state) => state.subscription)

  const [selectedEventName, setSelectedEventName] = useState('Event')

  const {
    clearFilter,
    handleExport,
    sortListOrders,
    tabOrderPlatform,
    setSelectFilterOrder,
    selectFilterOrder,
    searchString,
    onChangeSearch,
    onSelectTabEvent,
    tabOrderEvent,
    onSelectTabOrderPlatform,
    listOrdersEventFiltered,
  } = useContext(OrderRecordContext)

  const eventsByShop = getEventsByShop() || {}
  const listEventByShop = !isEmpty(eventsByShop) ? eventsByShop[selectedShop?.id] || [] : []

  const eidParam = new URLSearchParams(history.location.search).get('eid')
  const groupedEvents = groupEvents(listEventByShop, 5)
  const isLazadaValid = lazadaPackage?.shopId === selectedShop?.id
  const foundEvent = listEventByShop?.find((event) => event.id === eidParam)

  useEffect(() => {
    if (!eidParam) {
      setSelectedEventName('Event')
      onSelectTabEvent(null)
    }

    if (eidParam && foundEvent) {
      onSelectTabOrderPlatform(null)
      onSelectTabEvent(eidParam)

      setSelectedEventName(`Event: ${foundEvent.title}`)
    }

    if (selectedShop?.id && !eidParam) {
      onSelectTabOrderPlatform(tabOrderPlatform || PLATFORM_CO_KII)
    }
  }, [selectedShop?.id, eidParam, tabOrderPlatform, JSON.stringify(foundEvent)])

  const handleSelectEvent = (eventId, eventName) => {
    onSelectTabEvent(eventId)
    setSelectedEventName(`Event: ${eventName}`)
    onSelectTabOrderPlatform(null)
    history.push(`?eid=${eventId}`)
  }

  const onSelectTab = (tab) => {
    onSelectTabOrderPlatform(tab)
    onSelectTabEvent(null)
    setSelectedEventName('Event')
    history.replace(history.location.pathname)
  }

  const onExportOrder = () => {
    if (tabOrderPlatform === PLATFORM_CO_KII) {
      handleExport(sortListOrders, PLATFORM_CO_KII)
    }

    if (tabOrderEvent) {
      handleExport(listOrdersEventFiltered, tabOrderEvent)
    }
  }

  const shouldDisplayEvents = () => {
    if (listInvoiceSubscription.length === 1) {
      return listInvoiceSubscription[0].subscriptionPackageType !== PACKAGE_BASIC
    }
    if (listInvoiceSubscription.length > 1) {
      return listInvoiceSubscription.some(
        (subscription) =>
          subscription.subscriptionPackageType !== PACKAGE_BASIC &&
          subscription.status !== SUBSCRIPTION_STATUS_UNPAID
      )
    }
    return false
  }

  return (
    <>
      <h4 className='title mb-3'>
        {selectFilterOrder === 'reverse-order' && 'Reverse'} Order Records
      </h4>

      <div className='d-flex align-items-center mb-3'>
        <TabsECommerce
          onSelectTab={onSelectTab}
          tabOrderPlatform={tabOrderPlatform}
          lazadaPackage={lazadaPackage}
          isLazadaValid={isLazadaValid}
        />

        {shouldDisplayEvents() && (
          <TabEvent
            groupedEvents={groupedEvents}
            handleSelectEvent={handleSelectEvent}
            selectedEventName={selectedEventName}
            tabOrderEvent={tabOrderEvent}
          />
        )}
      </div>

      <div className='d-flex align-items-center justify-content-between'>
        <Search searchString={searchString} handleSearch={onChangeSearch} />
        {tabOrderPlatform === PLATFORM_LAZADA ? (
          <select
            name='filter-order'
            id='filter-order'
            className='form-select border rounded-0 w-auto text-secondary f-13 me-0'
            defaultValue={selectFilterOrder}
            onChange={(e) => setSelectFilterOrder(e.target.value)}
          >
            <option value='order'>Order</option>
            {/* <option value='reverse-order'>Reverse Order</option> */}
          </select>
        ) : (
          <>
            <SelectFilter />

            <button className='btn rounded-0 shadow-none border btn-clear' onClick={clearFilter}>
              <AiTwotoneFilter className='icon-filter' />
              <span className='ms-1'>Clear</span>
            </button>
          </>
        )}
      </div>

      {tabOrderPlatform !== PLATFORM_LAZADA ? (
        <div className='d-flex align-items-center justify-content-between my-3'>
          <RenderSelectingOrderDate />

          <div className='d-flex align-items-center ms-auto'>
            <Link to={`/${selectedShop?.shopSlug}`} className='create-link mx-3 text-uppercase'>
              Create
            </Link>
            <button
              className='bg-transparent border-0 text-uppercase mt-0 shadow-none btn btn-export rounded'
              onClick={onExportOrder}
            >
              Export
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default OrderManagementHeader
