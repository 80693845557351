import deliveryApi from '_api/delivery'
import userApi from '_api/user'
import { setUserInfo } from '_utils/localData'
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache'

const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
const GET_ADDRESS_DELIVERY_BOOKER = 'GET_ADDRESS_DELIVERY_BOOKER'
const GET_USER_INFO = 'GET_USER_INFO'

const initialState = {
  [DEFAULT_KEY]: null,
  notifications: [],
  addressDeliveryBooking: null,
  user: {},
  loadingNotification: false,
}

export const fetchUserInfo = () => async (dispatch) => {
  try {
    const { msgResp } = await userApi.getUser()
    dispatch({
      type: GET_USER_INFO,
      payload: { user: msgResp },
    })
    setUserInfo(msgResp)
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getNotifications = async (dispatch, getState) => {
  const { user } = getState()
  const { notifications } = user

  if (notifications && notifications.length > 0) return

  try {
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { loadingNotification: true },
    })
    const { msgResp: listNotification } = await userApi.getNotifications()
    dispatch({
      type: GET_NOTIFICATIONS,
      payload: { notifications: listNotification, loadingNotification: false },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

export const getRememberedDeliveryAddress = () => async (dispatch, getState) => {
  const { user } = getState()
  const { addressDeliveryBooking } = user
  if (addressDeliveryBooking) return
  try {
    const { msgResp } = await deliveryApi.getRememberedDeliveryAddress()
    dispatch({
      type: GET_ADDRESS_DELIVERY_BOOKER,
      payload: { addressDeliveryBooking: msgResp },
    })
  } catch (error) {
    throw new Error(error.message || error.msgResp)
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      return {
        ...state,
        [DEFAULT_KEY]: generateCacheTTL(),
        ...action.payload,
      }
    }
    case GET_ADDRESS_DELIVERY_BOOKER:
    case GET_USER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default user
