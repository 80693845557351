import NotFoundDataComponent from '_components/NotFoundDataComponent'
import TableHeaderWithSort from '_components/TableHeaderWithSort'
import { OrderRecordContext } from '_contexts/OrderRecordContext'
import { fetchListReverseOrderLazada } from '_redux/modules/order'
import moment from 'moment'
import React, { useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import ListReverseOrder from './ListReverseOrder'
import OrderRecordLazadaItem from './OrderRecordLazadaItem'

function ListOrderLazada() {
  const { listOrdersLazada } = useSelector((state) => state.order)
  const { searchString, sorting, setSorting, isLoading, selectFilterOrder } =
    useContext(OrderRecordContext)
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const { selectedShop } = useSelector((state) => state.shop)

  useEffect(() => {
    const fetchData = async () => {
      try {
        switch (selectFilterOrder) {
          case 'reverse-order': {
            const options = {
              shopId: selectedShop?.id,
              disputeInprogress: false,
              customDate: true,
              startDate: Number(`${moment().startOf('day').valueOf()}`.slice(0, 10)),
              endDate: Number(`${moment().endOf('day').valueOf()}`.slice(0, 10)),
              dateType: 'today',
              pageNo: 1,
              pageSize: 1,
            }
            await dispatch(fetchListReverseOrderLazada(options))
            break
          }
          default:
            break
        }
      } catch (error) {
        const errorMessage = `Error in ReverseOrder useEffect: ${error.message}`
        addToast(errorMessage, { appearance: 'error', autoDismiss: true })
      }
    }

    fetchData()
  }, [selectFilterOrder])

  const tableHeaders = [
    {
      name: 'Product',
      field: 'product',
      className: 'col col-4',
      type: 'string',
      sortable: true,
    },
    {
      name: 'Total',
      field: 'total',
      className: 'col',
      type: 'number',
      sortable: true,
    },
    {
      name: 'Fulfillment',
      field: 'fulfillment',
      className: 'col',
      type: 'string',
      sortable: true,
    },
    {
      name: 'Status',
      field: 'status',
      className: 'col',
      type: 'number',
      sortable: true,
    },
    {
      name: 'Actions',
      field: 'actions',
      className: 'col',
      type: 'number',
      sortable: true,
    },
  ]

  const listOrderSorted = useMemo(() => {
    if (searchString) {
      const listSearched = listOrdersLazada.filter((order) => order.phone === searchString)
      return listSearched
    }

    return listOrdersLazada
  }, [searchString, sorting, JSON.stringify(listOrdersLazada)])

  if (isLoading) {
    return <div className='text-center text-info fw-bold f-16 my-5'>Loading...</div>
  }

  if (selectFilterOrder === 'reverse-order') {
    return <ListReverseOrder />
  }

  if (!isLoading && listOrderSorted?.length === 0) {
    return <NotFoundDataComponent text='No Orders Found' />
  }

  return (
    <>
      <div className='list-order-record p-3 bg-light mt-3'>
        {!isLoading && (
          <TableHeaderWithSort
            headers={tableHeaders}
            defaultSortingField='shopOrderNo'
            defaultSortingOrder='asc'
            onSorting={(field, order, type) => setSorting({ field, order, type })}
            className='row p-3 table-header fw-bold bg-white mb-3 shadow'
          />
        )}

        {!isLoading &&
          listOrderSorted.map((item, index) => (
            <OrderRecordLazadaItem key={`${item.id}-${index}`} item={item} index={index} />
          ))}
      </div>
    </>
  )
}

export default ListOrderLazada
